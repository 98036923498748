import styled from "styled-components";

export const RaportsContainer = styled.div`
    max-width: 20rem;
    .select-wrapper {
      position: relative;
      select {
        font-family: "Montserrat", serif;
        border: none;
        border-radius: 30px;
        padding: 16px 30px;
        background-color: #F0F0F3;
        font-size: 13px;
        outline: none;
        margin-bottom: .6rem;
        width: 20rem;
        border-right: 2rem solid transparent;
      }
    }
  
`;