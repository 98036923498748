import styled from "styled-components";

export const Button = styled.input`
  font-family: "Montserrat", serif;
  border: none;
  border-radius: 30px;
  padding: 16px 30px;
  color: #ffffff;
  background-color: #181818;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  width: auto;
`;

export const Input = styled.input`
  font-family: "Montserrat", serif;
  border: none;
  border-radius: 30px;
  padding: 16px 30px;
  background-color: #F0F0F3;
  font-size: 13px;
  outline: none;
  margin-bottom: .6rem;
  ::placeholder {
    color: #000000;
    font-size: 13px;
  }
`;