import React, {useState, useEffect} from "react";
import Header from "../../../Header";
import {Form, MainContent} from "../../../Layout";
import axios from "axios";
import {url} from "../../../../utils/request";
import List, {ListItem, ListTitle} from "../../../List";
import {ArrowDown, TrashIcon} from "../../../../images";
import {Button, Input} from "../../../Button";
import * as S from '../../style';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const SystemEditNotificationTemplate = () => {

    const [categories, setCategories] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [sms, setSms] = useState(false);
    const [email, setEmail] = useState(false);
    const [selectedCatId, setSelectedCatId] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    useEffect(() => {
        axios.get(`${url}notificationSchema/${localStorage.getItem("editedSchema")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setTitle(res.data.notificationSchema.title)
                setContent(res.data.notificationSchema.content)
                setEmail(res.data.notificationSchema.email)
                setSms(res.data.notificationSchema.sms)
                setSelectedCategories(res.data.notificationSchema.categories)
                setSelectedCatId(res.data.notificationSchema.categories.map(e => e.id))
            })
            .catch(err => console.log(err));

        axios.get(`${url}category/all`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setCategories(res.data.items);
            })
            .catch(err => console.log(err))
    }, [])

    const editSchema = (e) => {
        e.preventDefault();

        const value = {
            "title": title,
            "content": content,
            "sms": sms,
            "email": email,
            "categories": selectedCatId,
            "type": "system"
        }

        axios.put(`${url}notificationSchema/${localStorage.getItem("editedSchema")}`, value ,{
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => console.log(res))
            .catch(err => console.log(err))
    }

    const addCatId = id => {
        if(selectedCatId.includes(parseInt(id))){
            toast.error("Ta sprawa już została dodana");
        }else{
            setSelectedCatId([...selectedCatId, parseInt(id)]);
            const value = categories.filter(el => el.id === parseInt(id));
            setSelectedCategories([...selectedCategories, value[0]]);
            console.log(selectedCategories);
        }
    }

    const deleteCat = id => {
        setSelectedCategories(selectedCategories.filter(el => el.id !== id));
        setSelectedCatId(selectedCatId.filter(el => el !== id));
    }

    const handleSetMail = () => {
        setEmail(true);
        setSms(false);
    }

    const handleSetSms = () => {
        setSms(true);
        setEmail(false);
    }

    return(
        <>
            <Header title="Edytuj szablon systemowy"/>
            <MainContent>
                <S.NotifTemplate>
                <Form onSubmit={editSchema} style={{top: "2rem"}}>
                    <div style={{maxWidth: "20rem"}}>
                        <input type="checkbox" checked={email} onChange={() => handleSetMail()}/> <span>E-mail</span>
                        <input style={{marginLeft: "1rem"}} type="checkbox" checked={sms} onChange={() => handleSetSms()}/> <span>SMS</span>
                    </div>
                    <h4 style={{marginTop:"1rem"}}>Edytuj sprawy</h4>
                    <List style={{top: "0", marginBottom: "3rem", width: "100%"}}>
                        <ListTitle>
                            <div style={{width: "90%"}}>
                                <p>Wybrane sprawy</p>
                                <img src={ArrowDown} alt="arrow-down"/>
                            </div>
                            <div style={{width: "10%"}}>
                                <p>Akcja</p>
                            </div>
                        </ListTitle>
                        {selectedCategories.map(s =>
                            <ListItem key={s.id}>
                                <div style={{width: "90%"}}>
                                    <p>{s.name}</p>
                                </div>
                                <div className="list_action_container" style={{width: "10%"}}>
                                    <img onClick={() => deleteCat(s.id)} src={TrashIcon} alt="trash-icon"/>
                                </div>
                            </ListItem>
                        )}
                    </List>
                    <div className="select-wrapper-notify" style={{marginTop: "-2rem"}}>
                        <select onChange={(e) => addCatId(e.target.value)} >
                            <option disabled selected value="">Przydziel do spraw</option>
                            {categories && categories.map(c => (
                                <option key={c.id} value={c.id}>{c.name}</option>
                            ))}
                        </select>
                    </div>
                    <h4>Tytuł</h4>
                    <Input
                        type="text"
                        placeholder="Tytuł"
                        style={{width: "20rem"}}
                        onChange={e => setTitle(e.target.value)}
                        value={title}
                    />
                    <h4>Treść</h4>
                    <textarea
                        placeholder="Treść"
                        cols="30"
                        rows="10"
                        value={content}
                        onChange={e => setContent(e.target.value)}
                    />
                    <Button
                        type="submit"
                        value="Zapisz edycję"
                    />
                </Form>
                </S.NotifTemplate>
            </MainContent>
            <ToastContainer/>
        </>
    )
};

export default SystemEditNotificationTemplate;