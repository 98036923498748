import React, {useState, useEffect} from "react";
import axios from "axios";
import {Button} from "../../Button/index";
import Header from "../../Header/index";
import {MainContent, Form} from "../../Layout/index";
import {url} from "../../../utils/request";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApplicationSettings = () => {

    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        axios.get(`${url}application/status`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setEnabled(res.data.enabled);
            })
            .catch(err => console.log(err))
    }, [])

    const saveSettings = (e) => {
        e.preventDefault();
        console.log(enabled);
        axios.put(`${url}application/changeStatus`, {
            status: enabled
        }, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            },
        }).then(res => {
            toast.success("Zapisano ustawienia aplikacji", {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
            toast.error("Wystąpił błąd podczas zapisywania ustawień aplikacji", {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    }

    return(
        <>
            <Header title="Ustawienia aplikacji" />
            <MainContent>
                <Form onSubmit={saveSettings}>
                <div style={{maxWidth: "20rem"}}>
                    <label htmlFor="enabled">Włączyć moduł w aplikacji?</label>
                    <input
                        style={{marginLeft: "11px"}} 
                        type="checkbox" onChange={() => { setEnabled(!enabled) }} 
                        checked={enabled}
                        />
                    </div>
                    <Button type="submit" value="Zapisz"/>
                </Form>
            </MainContent>
            <ToastContainer/>
        </>
    )
};

export default ApplicationSettings;