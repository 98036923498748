import styled from "styled-components";

export const ViewBox = styled.div`
  background-color: #FFFFFF;
  border-radius: .75rem;
  h4{
    margin: 0;
    padding: 1rem;
  }
  h3{
    margin: 0;
  }
  p{
    span{
      font-weight: bold;
      text-transform: uppercase;
    }
  }
 .textarea{
    font-family: "Montserrat", serif;
    width: 50%;
    max-width: 400px;
    border: none;
    border-radius: 30px;
    padding: 16px 30px;
    background-color: #F0F0F3;
    font-size: 13px;
    outline: none;
    margin-bottom: .6rem;
    ::placeholder {
      color: #000000;
      font-size: 13px;
    }
  }
`

export const Select = styled.div`
    position: relative;
    font-family: "Montserrat", serif;
    select {
      font-family: "Montserrat", serif;
      border: none;
      border-radius: 30px;
      padding: 16px 30px;
      background-color: #F0F0F3;
      font-size: 13px;
      outline: none;
      margin-bottom: 3rem;
      width: 20rem;
      border-right: 2rem solid transparent;
    }
  
`;