import React, {useState, useEffect} from "react";
import axios from "axios";
import Header from "../../Header/index";
import {MainContent, Form} from "../../Layout/index";
import {Input, Button} from "../../Button/index";
import {url} from "../../../utils/request";
import {DateHelper} from "../../../utils/DateHelper";
import * as S from "./style";

const Raports = () => {

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState();
    const [departments, setDepartments] = useState(null);
    const [chosenDepId, setChosenDepId] = useState("");

    useEffect(() => {
        axios.get(`${url}department/all`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                // console.log(res);
                setDepartments(res.data.items);
            })
            .catch(err => console.log(err))
    }, []);

    const getDepName = () => (departments.find(item => parseInt(item.id, 10) === parseInt(chosenDepId, 10))?.name ?? "wydzial");

    const generateRaport = (e) => {
        e.preventDefault()
        axios.get(`${url}report/${chosenDepId}?from=${dateFrom}&to=${dateTo}`,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/xlsx',
                    Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${getDepName()}-${DateHelper.withoutTimeToday()}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));
        // console.log(chosenDepId);
        // console.log(dateFrom);
        // console.log(dateTo);
        // https://api-ewizyty.wsparcie-jarjarocin.pl/api/V1/panel/report/1?department_id=2&from=2023-01-30&to=2023-03-12
     /*   axios.get(`${url}report/${chosenDepId}?from=${dateFrom}&to=${dateTo}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`,
                responseType: 'blob'
            }
        })
            .then(response => {

                // router.push(`${url}report/1?department_id=${chosenDepId}&from=${dateFrom}&to=${dateTo}`)

            })
            .catch(err => console.log(err))*/
    }
    return(
        <>
            <Header title="Raporty" />
            <MainContent>
                <Form onSubmit={generateRaport}>
                    <S.RaportsContainer>
                        <h4>Data od</h4>
                        <Input
                            type="date"
                            placeholder="Data od"
                            style={{width: "20rem"}}
                            onChange={e => setDateFrom(e.target.value)}
                            value={dateFrom}
                        />
                        <h4>Data do</h4>
                        <Input
                            type="date"
                            placeholder="Data do"
                            style={{width: "20rem"}}
                            onChange={e => setDateTo(e.target.value)}
                            value={dateTo}
                        />
                        <h4>Wybierz wydział</h4>
                        <div className="select-wrapper">
                            <select  onChange={(e) => setChosenDepId(e.target.value)} name="" id="" >
                                <option disabled selected value="">Wydział</option>
                                {departments && departments.map(d => (
                                    <option key={d.id} value={d.id}>{d.name}</option>
                                ))}
                            </select>
                        </div>
                    </S.RaportsContainer>
                    <Button
                        type="submit"
                        value="Generuj raport"
                    />
                </Form>
            </MainContent>
        </>
    )
}

export default Raports;