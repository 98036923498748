import React, {useState} from "react";
import axios from "axios";
import {url} from "../../../../utils/request";
import {toast, ToastContainer} from "react-toastify";
import Header from "../../../Header/index";
import {Button, Input} from "../../../Button/index";
import {MainContent, Form} from "../../../Layout";
import {useNavigate} from "react-router-dom";

const AddAdmin = () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const addNewAdmin = (e) => {
        e.preventDefault();

        const newAdmin = {
            "name": name,
            "role_id": 1,
            "phone": phone,
            "email": email,
            "departments": [],
            "password": password,
            "password_confirmation": passwordConfirm
        }

        axios.post(`${url}user` , newAdmin, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                toast.success(`Pomyślnie dodano administratora`);
                navigate("/panel-admin/administratorzy");
            })
            .catch(err => {
                toast.error(`Błąd! Spróbuj ponownie`)
            })
    }

    return(
            <>
                <Header title="Dodaj pracownika" />
                <MainContent>
                    <Form onSubmit={addNewAdmin}>
                        <div style={{maxWidth: "20rem"}}>
                            <Input
                                type="text"
                                placeholder="Imię i nazwisko"
                                style={{width: "20rem"}}
                                onChange={e => setName(e.target.value)}
                                value={name}
                            />
                            <Input
                                type="text"
                                placeholder="E-mail"
                                style={{width: "20rem"}}
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                            />
                            <Input
                                type="text"
                                placeholder="Telefon"
                                style={{width: "20rem"}}
                                onChange={e => setPhone(e.target.value)}
                                value={phone}
                            />
                            <Input
                                type="password"
                                placeholder="Hasło"
                                style={{width: "20rem"}}
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                            />
                            <Input
                                type="password"
                                placeholder="Potwierdź hasło"
                                style={{width: "20rem"}}
                                onChange={e => setPasswordConfirm(e.target.value)}
                                value={passwordConfirm}
                            />
                        </div>
                        <Button
                            type="submit"
                            value="Zapisz"
                        />
                    </Form>
                </MainContent>
                <ToastContainer/>
        </>
    )
};

export default AddAdmin;