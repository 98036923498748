import React from "react";
import {Line} from "react-chartjs-2";
import {Chart as ChartJS} from "chart.js/auto"
import gradient from 'chartjs-plugin-gradient';


const LineChart = ({chartData}) => {
    console.log(chartData)
    ChartJS.register(gradient);
    const options = {
        plugins: {
            legend: {
                display: false
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            }
        },
    }

    return(
        <Line data={chartData} options={options} />
    )
}
export default LineChart;