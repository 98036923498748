import styled from "styled-components";

export const Navigation = styled.div`
  width: 14.3rem;
  height: 100vh;
  background-color: #FFFFFF;
  position: fixed;
  overflow: auto;
  left: 0;
  .navigation_logo{
    width: 76%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    height: auto;
    margin-top: 1.75rem;
  }
  nav{
    ul {
      list-style: none;
      margin-top: 2.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
            .second_nav{
              margin-left: 2rem;
              height: 30px;
              a{
                color: #646262;
                font-size: 14px;
                font-weight: 700;
                margin-top: .5rem;
              }
            }
          .active{
            li{
              .a-container{
                background-color: #FDEBED;
                width: 12rem;;
                border-radius: 4px;
                margin-left: -22px;
                height: 3rem;
                img{
                  margin-left: 22px;
                }
              }
              .border{
                position: absolute;
                right: 0;
                width: 2px;
                background-color: #CB444A;
                height: 3rem;
                border-radius: 2px;
              }
            }
          }
        li {
          width: 100%;
          height: 4rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          
          .a-container {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;

            img {
              width: 22px;
              height: 22px;
              filter: invert(40%) sepia(0%) saturate(41%) hue-rotate(252deg) brightness(94%) contrast(91%);
            }

            p {
              color: #646262;
              font-size: 14px;
              font-weight: 700;
              margin-left: 1.75rem;
            }
          }
        }
    }
  }
  .navigation_logout_button{
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 1.5rem;
    margin: 2rem 0;
    @media screen and (max-height: 800px){
      position: relative;
      margin-top: 50px;
    }
  }
`;