import styled from "styled-components";

export const Header = styled.div`
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .2rem;
  h2{
    font-size: 1.25rem;
  }
  .select-wrapper {
    position: relative;
    font-family: "Montserrat", serif;
    select {
      font-family: "Montserrat", serif;
      border: none;
      border-radius: 30px;
      padding: 16px 30px;
      background-color: #F0F0F3;
      font-size: 13px;
      outline: none;
      margin-bottom: .6rem;
      width: 20rem;
      border-right: 2rem solid transparent;
    }
  }
`;