import styled from "styled-components";

export const Pagination = styled.div`
  background-color: #fff;
  position: relative;
  top: -4.5rem;
  height: 56px;
  border-radius: 0 0 20px 20px;
  .pagination_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    left: 50%;
    transform: translate(-50%, 0);
    list-style: none;
    position: relative;
    font-family: "Lato", serif;
    padding-left: 0;
  }
  .pagination_pages{
    font-size: 14px;
    color: #1C1D21;
    font-weight: 600;
    cursor: pointer;
    margin: 0 .5rem;
    padding: 0 0 0 .4rem;
  }
  .pagination_pages_active{
    color: #FFFFFF;
    font-weight: 600;
    background-color: #CB444A;
    cursor: pointer;
    padding: .5rem .7rem;
    border-radius: 5px;
    margin: 0 .3rem;
  }
  .pagination_nav{
    display: flex;
    //align-items: center;
    margin: 0 2.5rem;
    .label_img_box{
      margin: 0 11px;
      width: 40px;
      height: 40px;
      background-color: #F5F5FA;
      border-radius: 5px;
      img{
        width: 18px;
        height: 18px;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    p{
      font-size: 14px;
      color: #8181A5;
      font-weight: 400;
    }
  }
  .pagination_nav_a{
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;