import request from "../utils/request";
const fetchCases = async (page) => {
    try {
        const { data, status } = await request({
            url: `category?page=${page}`,
            method: "GET",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const fetchAllCases = async () => {
    try {
        const { data, status } = await request({
            url: `category/all`,
            method: "GET",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const deleteCase = async (id) => {
    try {
        const { data, status } = await request({
            url: `category/${id}`,
            method: "DELETE",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

export {
    fetchCases,
    deleteCase,
    fetchAllCases
};