import React, {useState, useEffect} from "react";
import axios from "axios";
import {Button, Input} from "../../Button/index";
import Header from "../../Header/index";
import {MainContent, Form} from "../../Layout/index";
import {url} from "../../../utils/request";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminSettings = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(() => {
        axios.get(`${url}user/${localStorage.getItem("userId")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setName(res.data.user.name);
                setEmail(res.data.user.email);
                setPhone(res.data.user.phone);
            })
            .catch(err => console.log(err))
    }, [])

    const saveSettings = (e) => {
        e.preventDefault();
        const value = {
            "email": email,
            "name": name,
            "password": newPassword,
            "password_confirmation": confirmNewPassword,
            "currentPassword": oldPassword,
            "phone": phone
        }

        axios.put(`${url}settings`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success(`Pomyślnie dokonano zmian`);
                setNewPassword('')
                setOldPassword('')
                setConfirmNewPassword('')
            })
            .catch(err => {
                console.log(err)
                toast.error(`Błąd! Uzupełnij wszystkie dane poprawnie`);

            })
    }

    return(
        <>
            <Header title="Ustawienia" />
            <MainContent>
                    <Form onSubmit={saveSettings}>
                        <div style={{maxWidth: "20rem"}}>
                            <h4>Imię i nazwisko</h4>
                            <Input
                                type="text"
                                placeholder="Imię i nazwisko"
                                style={{width: "20rem"}}
                                onChange={e => setName(e.target.value)}
                                value={name}
                            />
                            <h4>Email</h4>
                            <Input
                                type="text"
                                placeholder="Email"
                                style={{width: "20rem"}}
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                            />
                            <h4>Telefon</h4>
                            <Input
                                type="text"
                                placeholder="Telefon"
                                style={{width: "20rem"}}
                                onChange={e => setPhone(e.target.value)}
                                value={phone}
                            />
                            <h4>Hasło</h4>
                            <Input
                                type="password"
                                placeholder="Obecne Hasło"
                                style={{width: "20rem"}}
                                onChange={e => setOldPassword(e.target.value)}
                                value={oldPassword}
                            />
                            <Input
                                type="password"
                                placeholder="Nowe hasło"
                                style={{width: "20rem"}}
                                onChange={e => setNewPassword(e.target.value)}
                                value={newPassword}
                            />
                            <Input
                                type="password"
                                placeholder="Powtórz nowe hasło"
                                style={{width: "20rem"}}
                                onChange={e => setConfirmNewPassword(e.target.value)}
                                value={confirmNewPassword}
                            />
                        </div>
                        <Button
                            type="submit"
                            value="Zapisz"
                        />
                    </Form>
            </MainContent>
            <ToastContainer/>
        </>
    )
};

export default AdminSettings;