import React, {useState, useContext} from 'react';
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import plLocale from "@fullcalendar/core/locales/pl";
import moment from "moment/moment";
import FullCalendar from "@fullcalendar/react";
import {useNavigate, useOutletContext} from "react-router-dom";
import * as S from "../style";
import axios from "axios";
import {url} from "../../../utils/request";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingComponent from "../../Loading";
import {LoadingContext} from "../../../App";

const QuickModal = ({xPos, yPos, showMenu, setShowMenu, currentEvent, showMenuAbsent, setShowMenuAbsent}) => {
    const navigate = useNavigate();

    const [showSecondModal, setShowSecondModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [currStatus, setCurrStatus] = useState(null);

    const convertStatusesBtns = (status) => {
        switch (status){
            case "done":
                return ["in_progress", "missing", "new"];
            case "in_progress":
                return ["done", "missing", "new"];
            case "missing":
                return ["done", "in_progress", "new"];
            case "new":
                return ["done", "in_progress", "missing"];
            default:
                return ["in_progress", "missing", "new"];
        }
    }
    const convertStatusLanguage = (status) => {
        switch (status){
            case "done":
                return "Załatwiona";
            case "in_progress":
                return "W trakcie";
            case "missing":
                return "Brak petenta";
            case "new":
                return "Nowa";
            default:
                return "Nowa";
        }
    }
    const convertStatusColor = (status) => {
        switch (status){
            case "done":
                return "#46B489";
            case "in_progress":
                return "#F29C38";
            case "missing":
                return "#000000";
            case "new":
                return "#CB444A";
            default:
                return "#46B489";
        }
    }

    const fetchStatuses = () => {
        setCurrStatus(null);
        axios.get(`${url}reservation/${currentEvent}?department_id=${localStorage.getItem("selectedDepId")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setCurrStatus(res.data.reservation.status)
            })
            .catch(err => console.log(err))
    }

    const changeStatus = (status) => {
        axios.put(`${url}reservation/status/${currentEvent}?department_id=${localStorage.getItem("selectedDepId")}&status=${status}`, {}, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success(`Pomyślnie wykonano operację`);
                setShowMenu(false);
                window.location.reload();
            })
            .catch(() => {
                toast.error(`Błąd! Spróbuj ponownie`);
            })

    }

    const deleteReservation = () => {
        axios.delete(`${url}reservation/${currentEvent}?department_id=${localStorage.getItem("selectedDepId")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success(`Pomyślnie wykonano operację`);
                setShowMenu(false);
                window.location.reload();
            })
            .catch(() => {
                toast.error(`Błąd! Spróbuj ponownie`);
            })
    };

    const deleteAbsence = () => {
        axios.delete(`${url}absent/${currentEvent}?department_id=${localStorage.getItem("selectedDepId")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success(`Pomyślnie wykonano operację`);
                setShowMenu(false);
                window.location.reload();
            })
            .catch(() => {
                toast.error(`Błąd! Spróbuj ponownie`);
            })
    };


    return (
        <>
            {showMenu &&
                <div onMouseLeave={() => setShowMenu(false)}>
                    <S.QuickModal style={{ left: `${xPos - 250}px`, top: `${yPos - 120}px`}}>
                        <button onClick={() => {
                            localStorage.setItem("reservationId", currentEvent)
                            if(localStorage.getItem("userRole") === "2"){
                                navigate("/panel-employee/rezerwacja");
                            } else {
                                navigate("/panel-admin/kalendarze/rezerwacja");
                            };
                        }
                        }>Szczegóły</button>
                        <button onClick={() => {
                            localStorage.setItem("reservationId", currentEvent);
                            if(localStorage.getItem("userRole") === "2"){
                                navigate("/panel-employee/edytuj");
                            } else {
                                navigate("/panel-admin/kalendarze/edytuj");
                            };
                        }}>Edycja</button>
                        <button onClick={() => {
                            setShowSecondModal(true);
                            fetchStatuses();
                        }}>Zmiana statusu</button>
                        <button onClick={() => {
                            setShowDeleteModal(true);
                        }}>Usuń</button>

                    </S.QuickModal>
                    {showSecondModal &&
                        <S.SecondModal onMouseLeave={() => setShowSecondModal(false)} style={{ left: `${xPos - 140}px`, top: `${yPos - 70}px`}}>
                            {currStatus ? convertStatusesBtns(currStatus).map(el => (
                                    <button onClick={() => changeStatus(el)} style={{color: convertStatusColor(el)}}>{convertStatusLanguage(el)}</button>
                                )) : <p>Czekaj...</p>
                            }
                        </S.SecondModal>
                    }
                    {showDeleteModal &&
                        <S.DeleteModal onMouseLeave={() => setShowDeleteModal(false)} style={{ left: `${xPos - 140}px`, top: `${yPos - 70}px`}}>
                            <h5>Na pewno?</h5>
                            <button
                                style={{color: "#CB444A", marginLeft: "10px"}}
                                onClick={() => {
                                deleteReservation();
                            }}>TAK</button>
                            <button onClick={() => setShowDeleteModal(false)}>NIE</button>
                        </S.DeleteModal>
                    }
                </div>
            }
            {showMenuAbsent &&
                <div onMouseLeave={() => setShowMenuAbsent(false)}>
                    <S.QuickModal style={{ left: `${xPos - 250}px`, top: `${yPos - 60}px`, height: "60px"}}>
                        {/*<button onClick={() => {*/}
                        {/*    localStorage.setItem("reservationId", currentEvent)*/}
                        {/*    if(localStorage.getItem("userRole") === "2"){*/}
                        {/*        navigate("/panel-employee/rezerwacja");*/}
                        {/*    } else {*/}
                        {/*        navigate("/panel-admin/kalendarze/rezerwacja");*/}
                        {/*    };*/}
                        {/*}*/}
                        {/*}>Szczegóły</button>*/}
                        <button onClick={() => {
                            localStorage.setItem("editedAbsenceId", currentEvent)
                            if(localStorage.getItem("userRole") === "2"){
                                navigate("/panel-employee/edytuj2");
                            } else {
                                navigate("/panel-admin/kalendarze/edytuj2");
                            };
                        }}>Edycja</button>
                        <button onClick={() => {
                            setShowDeleteModal(true);
                        }}>Usuń</button>
                    </S.QuickModal>
                    {showDeleteModal &&
                        <S.DeleteModal onMouseLeave={() => setShowDeleteModal(false)} style={{ left: `${xPos - 195}px`, top: `${yPos - 70}px`}}>
                            <h5>Na pewno?</h5>
                            <button
                                style={{color: "#CB444A", marginLeft: "10px"}}
                                onClick={() => {
                                    deleteAbsence();
                                }}>TAK</button>
                            <button onClick={() => setShowDeleteModal(false)}>NIE</button>
                        </S.DeleteModal>
                    }
                </div>
            }
        </>
    )
};






export const DayGrid = () => {
    const navigate = useNavigate();
    const [eventDate, truePositions, date, setEventDate, trueMonth, startMonthDate, setStartMonthDate, endMonthDate, setEndMonthDate, refresh, setRefresh] = useOutletContext();
    const [xPos, setXPos] = useState();
    const [yPos, setYPos] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const [showMenuAbsent, setShowMenuAbsent] = useState(false);
    const [currentEvent, setCurrentEvent] = useState(null);

    const [loading, setLoading] = useContext(LoadingContext);

    return(
        <>
            {loading ?
            <FullCalendar
                datesSet={(date) => {
                    setEventDate(date.start);
                }}
                eventDidMount={ (arg) => {
                    arg.el.addEventListener("contextmenu", (event) => {
                        if(arg.event.title !== "Nieobecność") {
                            event.preventDefault();
                            setCurrentEvent(arg.event.id);
                            setXPos(event.pageX);
                            setYPos(event.pageY);
                            setShowMenu(true)
                        } else {
                            event.preventDefault();
                            setCurrentEvent(arg.event.id);
                            setXPos(event.pageX);
                            setYPos(event.pageY);
                            setShowMenuAbsent(true)
                        }
                    })
                }}
                eventMouseLeave={() => {
                    setShowMenu(false)
                }}
                plugins={[ resourceTimeGridPlugin ]}
                locale={plLocale}
                timeZone={'Europe/Warsaw'}
                initialView="resourceTimeGridDay"
                initialDate={eventDate ? eventDate : date}
                resources={truePositions.positions}
                schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'}
                scrollTime={moment().format("HH:mm:ss")}
                nowIndicator={true}
                headerToolbar={{
                    start: 'title',
                    center: '',
                    end: 'today prev,next'
                }}
                titleFormat={{ year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'} }
                slotLabelClassNames="time-line-label"
                slotLaneClassNames="time-line-content"
                slotMinTime="08:00:00"
                slotMaxTime="19:00:00"
                slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                }}
                allDaySlot={false}
                height={590}
                displayEventTime={false}
                eventClick={(e) => {
                    localStorage.setItem("reservationId",e.event.id)
                    if(e.event.title !== "Nieobecność") {
                        if (localStorage.getItem("userRole") === "2") {
                            navigate("/panel-employee/rezerwacja");
                        } else {
                            navigate("/panel-admin/kalendarze/rezerwacja");
                        }
                    }
                }}
                eventSources={[
                    {
                        events: truePositions.events.filter(p => p.status === "new"),
                        color: '#FAECED',
                        textColor: '#CB444A',
                    },
                    {
                        events: truePositions.events.filter(p => p.status === "in_progress"),
                        color: '#A6FAFF',
                        textColor: '#0000FF',
                    },
                    {
                        events: truePositions.events.filter(p => p.status === "done"),
                        color: '#EDF7F3',
                        textColor: '#46B489',
                    },
                    {
                        events: truePositions.events.filter(p => p.status === "missing"),
                        color: '#332E30',
                        textColor: '#fff',
                    },
                    {
                        events: truePositions.events.filter(p => p.type === "absent"),
                        color: '#F0F0F3',
                        textColor: '#212226'
                    }
                ]}
            />
            :
                <LoadingComponent style={{marginTop: "100px"}}/>
        }
            {(showMenu || showMenuAbsent) &&
                <QuickModal
                    currentEvent={currentEvent}
                    xPos={xPos}
                    yPos={yPos}
                    setXPos={setXPos}
                    setYPos={setYPos}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    showMenuAbsent={showMenuAbsent}
                    setShowMenuAbsent={setShowMenuAbsent}
                />
            }
            <ToastContainer/>
        </>
    )
};

export default DayGrid;