export class DateHelper {

    static withoutTimeToday = () => {
        let dateObj = new Date();
        let getCurrentMonth = dateObj.getMonth()+1;
        let getCurrentDay = dateObj.getDate();
        if(getCurrentMonth < 10)
        {
            getCurrentMonth = `0${getCurrentMonth}`;
        }
        if(getCurrentDay < 10)
        {
            getCurrentDay = `0${getCurrentDay}`;
        }
        return `${getCurrentDay}.${getCurrentMonth}.${dateObj.getFullYear()}`;
    }

    static withoutTime = (date) => {
        let dateObj = new Date(date);
        return `${dateObj.getDay()}.${dateObj.getMonth()+1}.${dateObj.getFullYear()}`;
    }
}