import React from "react";
import {Outlet} from "react-router-dom";
import Layout from "../Layout";



const PanelAdmin = () => {
    return(
        <Layout>
            <Outlet/>
        </Layout>
    )
};

export default PanelAdmin;