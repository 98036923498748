import React, {useEffect, useState} from "react";
import axios from "axios";
import {url} from "../../../utils/request";
import Pagination from "../../Pagination";
import Header from "../../Header";
import {HalfMainContent, Form} from "../../Layout";
import {Input, Button} from "../../Button";
import * as S from '../style';
import {ArrowDown, EditIcon, TrashIcon} from "../../../images";
import List, {ListTitle, ListItem} from "../../List";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import LoadingComponent from "../../Loading";
import {fetchAllCounters} from "../../../services/counters.service";
import {fetchAbsences, deleteAbsence} from "../../../services/absences.service";

const EmployeeAbsences = () => {
    const navigate = useNavigate();

    const [dateFrom, setDateFrom] = useState("");
    const [timeFrom, setTimeFrom] = useState("08:00");
    const [timeTo, setTimeTo] = useState("16:00");
    const [positions, setPositions] = useState(null);
    const [absences, setAbsences] = useState(null);
    const [selectedPosId, setSelectedPosId] = useState();
    const [refresh, setRefresh] = useState(false);

    const [selectedDepId, setSelectedDepId] = useState(JSON.parse(localStorage.getItem("selectedDepId")));
    const [departments, setDepartments] = useState(JSON.parse(localStorage.getItem("departments")));

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        fetchAllCounters(selectedDepId)
            .then(res => {
                setPositions(res.items);
            })
            .catch(err => console.log(err));

        fetchAbsences(page, selectedDepId)
            .then(res => {
                setAbsences(res.items);
                setLastPage(res.lastPage);
            })
            .catch(err => console.log(err))
    }, [selectedDepId, refresh, page]);
    useEffect(() => localStorage.setItem("selectedDepId", selectedDepId.toString()), [selectedDepId]);

    const handleDeleteAbsence = async (id, name) => {
        await deleteAbsence(id, selectedDepId)
            .then(() => {
                toast.success(`Pomyślnie usunięto nieobecność ${name}`)
                setRefresh(!refresh)
            })
            .catch(() => toast.error("Błąd! Spróbuj ponownie"))
    }
    const addAbsence = (e) => {
        e.preventDefault();
        const value = {
            "counter_id": selectedPosId,
            "from": `${dateFrom} ${timeFrom}`,
            "to": `${dateFrom} ${timeTo}`,
            "department_id": selectedDepId
        }
        axios.post(`${url}absent`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success(`Pomyślnie dodano nieobecność`);
                setRefresh(!refresh);
                setDateFrom('');
                // setDateTo('');
                setTimeTo('');
                setTimeFrom('');
            })
            .catch((err) => {
                console.log(err)
                if(err.response.data.message.slice(0, 3) === 'Can'){
                    toast.error(`Błąd! W tym czasie są wizyty lub nieobecności`)
                } else toast.error(`Błąd! Uzupełnij dane prawidłowo`)
            })
    }

    return(
        <>
            <Header title="Nieobecności">
                <div className="select-wrapper">
                    <select onChange={(e) => {
                        setSelectedDepId(e.target.value);
                    }} name="" id="" >
                        {departments && departments.map(d => (
                            <option selected={d.id === selectedDepId} key={d.id} value={d.id}>{d.name}</option>
                        ))}
                    </select>
                </div>
            </Header>
            <S.EmployeeAbsences>
                <HalfMainContent style={{width: "calc(40% - .5rem)"}}>
                        <Form onSubmit={addAbsence}>
                            <div style={{maxWidth: "20rem"}}>
                            <div className="select-wrapper">
                                <select onChange={(e) => {
                                    setSelectedPosId(e.target.value);
                                }} name="" id="" >
                                    <option disabled selected value="">Stanowisko</option>
                                    {positions && positions.map(d => (
                                        <option key={d.id} value={d.id}>{d.name}</option>
                                    ))}
                                </select>
                            </div>
                                <h4>Data </h4>
                            <Input
                                type="date"
                                style={{width: "16.2rem"}}
                                onChange={e => setDateFrom(e.target.value)}
                                value={dateFrom}
                            />
                                <h4 style={{marginTop: "1rem"}}>Godz od</h4>
                            <Input
                                type="time"
                                style={{width: "16.2rem"}}
                                onChange={e => setTimeFrom(e.target.value)}
                                value={timeFrom}
                            />
                                <h4>Godz do</h4>
                            <Input
                                type="time"
                                style={{width: "16.2rem"}}
                                onChange={e => setTimeTo(e.target.value)}
                                value={timeTo}
                            />
                            </div>
                            <Button
                                type="submit"
                                value="Zapisz"
                            />
                        </Form>
                </HalfMainContent>
                <HalfMainContent style={{marginLeft: "1rem", width: "calc(60% - .5rem)"}}>
                    <h3>Lista nieobecności</h3>
                    <List style={{top: "3.4rem"}}>
                        <ListTitle>
                            <div style={{width: "35%"}}>
                                <p>Data</p>
                                <img src={ArrowDown} alt="arrow-down"/>
                            </div>
                            <div style={{width: "35%"}}>
                                <p>Stanowisko</p>
                                <img src={ArrowDown} alt="arrow-down"/>
                            </div>
                            <div style={{width: "30%"}}>
                                <p>Akcja</p>
                            </div>
                        </ListTitle>
                        {absences && absences.map(a => (
                            <ListItem>
                                <div style={{width: "35%"}}>
                                    <p style={{maxWidth: "110px"}}>{a.from}</p>
                                </div>
                                <div style={{width: "35%"}}>
                                    <p>{a.counter}</p>
                                </div>
                                <div className="list_action_container" style={{width: "30%"}}>
                                    <img
                                        src={EditIcon}
                                        alt="edit-icon"
                                        onClick={() => {
                                            localStorage.setItem("editedAbsenceId", a.id)
                                            navigate("/panel-employee/nieobecnosci/edytuj")
                                        }}
                                    />
                                    <img
                                        src={TrashIcon}
                                        alt="trash-icon"
                                        onClick={() => handleDeleteAbsence(a.id, a.counter)}
                                    />
                                </div>
                            </ListItem>
                        ))}
                    </List>
                    {!absences && <LoadingComponent/>}
                    <Pagination page={page} setPage={setPage} lastPage={lastPage} />
                </HalfMainContent>
                <ToastContainer />
            </S.EmployeeAbsences>
        </>
    )
};

export default EmployeeAbsences;