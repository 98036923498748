import React, {useState, useEffect} from "react";
import axios from "axios";
import {url} from "../../../../../utils/request";
import Header from "../../../../Header";
import {MainContent, Form} from "../../../../Layout";
import {Button, Input} from "../../../../Button";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import {useNavigate} from "react-router-dom";

const EditAbsence = () => {
    // const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${url}absent/${localStorage.getItem("editedAbsenceId")}?department_id=${localStorage.getItem("selectedDepId")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                console.log(res)
                setDateFrom(res.data.absent.from.slice(0, 10))
                setTimeFrom(res.data.absent.from.slice(11,16))
                setDateTo(res.data.absent.to.slice(0, 10))
                setTimeTo(res.data.absent.to.slice(11,16))
                setCounterId(res.data.absent.counter_id);
            })
            .catch(err => console.log(err))
    }, [])

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [timeFrom, setTimeFrom] = useState('');
    const [timeTo, setTimeTo] = useState('');
    const [counterId, setCounterId] = useState();

    const saveAbsence = (e) => {
        e.preventDefault();

        const value = {
            "counter_id": counterId,
            "from": `${dateFrom} ${timeFrom}`,
            "to": `${dateFrom} ${timeTo}`,
            "department_id": localStorage.getItem("selectedDepId")
        };

        console.log(value);

        axios.put(`${url}absent/${localStorage.getItem("editedAbsenceId")}`, value , {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success("Pomyślnie edytowano nieobecność");
                // navigate("/panel-admin/kalendarze/nieobecnosci");
            })
            .catch((err) => {
                toast.error("Błąd! Uzupełnij poprawnie dane")
                console.log(err);
            })
    }

    return(
        <>
            <Header title="Edytuj nieobecność" />
            <MainContent>
                <Form onSubmit={saveAbsence}>
                    <div style={{maxWidth: "20rem"}}>
                        <h4>Data</h4>
                        <Input
                            type="date"
                            onChange={e => setDateFrom(e.target.value)}
                            value={dateFrom}
                            style={{width: "20rem"}}
                        />
                        {/*<h4>Data do</h4>*/}
                        {/*<Input*/}
                        {/*    type="date"*/}
                        {/*    onChange={e => setDateTo(e.target.value)}*/}
                        {/*    value={dateTo}*/}
                        {/*    style={{width: "20rem"}}*/}
                        {/*/>*/}
                        <h4>Godz od</h4>
                        <Input
                            type="time"
                            onChange={e => setTimeFrom(e.target.value)}
                            value={timeFrom}
                            style={{width: "20rem"}}
                        />
                        <h4>Godz do</h4>
                        <Input
                            type="time"
                            onChange={e => setTimeTo(e.target.value)}
                            value={timeTo}
                            style={{width: "20rem"}}
                        />
                    </div>
                    <Button
                        type="submit"
                        value="Zapisz"
                    />
                </Form>
            </MainContent>
            <ToastContainer/>
        </>
    )
};

export default EditAbsence;