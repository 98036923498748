import React, {useState, useEffect} from "react";
import Header from "../../../Header/index";
import {MainContent, Form} from "../../../Layout/index";
import {Input, Button} from "../../../Button/index"
import * as S from "../style";
import axios from "axios";
import {url} from "../../../../utils/request";
import {TrashIcon} from "../../../../images/index";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";

const Case = ({id, onChangeName, onChangeTime, onChangeRoom, name, time, room, onClick, action}) => {
    return(
        <div className={action === "delete" && "deleted"} style={{marginLeft: "2.3rem", display: "flex", alignItems: "center"}}>
            <Input
                type="text"
                value={name}
                style={{width: "20rem", backgroundColor: "#ddd"}}
                onChange={onChangeName}
            />
            <Input
                type="number"
                step="5"
                min={0}
                value={time}
                style={{width: "10rem", marginLeft: "1rem", backgroundColor: "#ddd"}}
                onChange={onChangeTime}
            />
             <Input
                type="text"
                placeholder="Pokój numer X oraz Y"
                style={{width: "10rem", marginLeft: "1rem", backgroundColor: "#ddd"}}
                onChange={onChangeRoom}
                value={room}
            />
            {id &&
                <img
                    src={TrashIcon}
                    alt="trash-icon"
                    style={{marginBottom: "1.5rem"}}
                    onClick={onClick}
                />
            }
        </div>
    )
}

const EditCase = () => {
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);


    const [departments, setDepartments] = useState(null);
    const [chosenDepId, setChosenDepId] = useState(null);
    const [name, setName] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [time, setTime] = useState("");
    const [room, setRoom] = useState("");

    const [subCategories, setSubCategories] = useState([]);

    useEffect(() => {
        axios.get(`${url}department/all`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setDepartments(res.data.items);
            })
            .catch(err => console.log(err))
        axios.get(`${url}category/${localStorage.getItem("editedCaseId")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                let tempArr = [];
                res.data.category.children.map(i => {
                    tempArr = [...tempArr, {name: i.name, time: i.time, room_number: i.room_number, id: i.id, action: "update"}]
                })
                setSubCategories(tempArr);
                setName(res.data.category.name)
                setRoom(res.data.category.room)
                setChosenDepId(res.data.category.department_id)
            })
            .catch(err => console.log(err))
    }, [refresh]);

    const addCase = () => {
        if(subCategory.length > 1 && time > 1) {
            setSubCategories([...subCategories, {name: subCategory, time: time, room_number: room, action: "create"}]);
            setSubCategory('');
            setTime('');
            setRoom('');
        } else toast.error(`Błąd! Uzupełnij dane`);
    }

    const saveCase = (e) => {
        e.preventDefault();
        const value = {
            "department_id": chosenDepId,
            "name": name,
            "children": subCategories
        }

        axios.put(`${url}category/${localStorage.getItem("editedCaseId")}`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                toast.success(`Pomyślnie edytowano sprawę`);
                navigate('/panel-admin/sprawy');
            })
            .catch(err => {
                console.log(err);
                toast.error(`Błąd! Spróbuj ponownie`);
            });
    };


    const changeName = (index, e) => {
        let newArr = [...subCategories];
        newArr[index].name = e.target.value;
        setSubCategories(newArr);
    };

    const changeTime = (index, e) => {
        let newArr = [...subCategories];
        newArr[index].time = e.target.value;
        setSubCategories(newArr);
    }

    const changeRoom = (index, e) => {
        let newArr = [...subCategories];
        newArr[index].room_number = e.target.value;
        setSubCategories(newArr);
    }
    // console.log(subCategories);

    const deleteCategoryCase = (id) => {

        let tempArr = subCategories;
        let index = subCategories.findIndex(i => i.id === id);
        console.log(index);
        tempArr[index].action = "delete";
        setSubCategories(tempArr);

        const value = {
            "department_id": chosenDepId,
            "name": name,
            "children": subCategories
        }
        console.log(value);

        axios.put(`${url}category/${localStorage.getItem("editedCaseId")}`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                console.log(res);
                toast.success(`Pomyślnie usunięto sprawę`);
                setRefresh(!refresh);
            })
            .catch(err => {
                console.log(err);
                toast.error(`Błąd! Spróbuj ponownie`);
            });
    }

    return (
        <>
            <Header title="Edytuj Sprawę"/>
            <MainContent>
                <Form onSubmit={saveCase}>
                    <S.CasesContainer>
                        <div className="select-wrapper">
                            <select onChange={(e) => {
                                setChosenDepId(e.target.value)
                            }} name="" id="" >
                                <option disabled selected value="">Zmień wydział</option>
                                {departments && departments.map(d => (
                                    <option key={d.id} value={d.id} name={d.name}>{d.name}</option>
                                ))}
                            </select>
                        </div>
                        <Input
                            type="text"
                            placeholder="Kategoria"
                            style={{width: "20rem"}}
                            onChange={e => setName(e.target.value)}
                            value={name}
                        />
                        <div style={{marginLeft: "2.3rem"}}>
                            <Input
                                type="text"
                                placeholder="Podkategoria"
                                style={{width: "20rem"}}
                                onChange={e => setSubCategory(e.target.value)}
                                value={subCategory}
                            />
                            <Input
                                type="number"
                                placeholder="Czas realizacji w min"
                                style={{width: "10rem", marginLeft: "1rem"}}
                                onChange={e => setTime(e.target.value)}
                                value={time}
                                step="5"
                                min={0}
                            />
                            <Input
                                type="text"
                                placeholder="Pokój numer X oraz Y"
                                style={{width: "10rem", marginLeft: "1rem"}}
                                onChange={e => setRoom(e.target.value)}
                                value={room}
                            />
                            <input onClick={addCase} type="button" className="add_button" value="+"/>
                        </div>
                        {subCategories.map((c, index) =>
                            <Case
                                name={c.name}
                                time={c.time}
                                id={c.id}
                                onChangeName={(e) => changeName(index, e)}
                                onChangeTime={(e) => changeTime(index, e)}
                                onChangeRoom={(e) => changeRoom(index, e)}
                                room={c.room_number}
                                action={c.action}
                                onClick={() => {
                                    // setSubCategoriesV(subCategoriesView.filter(item => item.name !== name));
                                    // let tempArr = subCategories;
                                    // let index = subCategories.findIndex(i => i.name = c.name);
                                    // tempArr[index].action = "delete";
                                    // setSubCategories(tempArr);
                                    deleteCategoryCase(c.id)

                                }}
                            />
                        )}
                    </S.CasesContainer>
                    <Button
                        type="submit"
                        value="Zapisz"
                    />
                </Form>
            </MainContent>
            <ToastContainer/>
        </>
    )
}

export default EditCase;