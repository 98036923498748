import React from 'react';
import {Button} from "./Button";
import {useNavigate} from "react-router-dom";

const ErrorElement = () => {
    const navigate = useNavigate();
    return(
        <div style={{width: "100%", height: "100vh", position: "relative"}}>
            <div style={{width: "auto", height: "auto", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}>
                <h2 style={{marginBottom: "3rem"}}>Ops, coś poszło nie tak...</h2>
                <Button
                    style={{marginLeft: "1rem"}}
                    type="button"
                    value="Zaloguj się ponownie"
                    onClick={() => {
                        localStorage.clear();
                        window.sessionStorage.clear();
                        navigate("/");
                    }}
                />
            </div>
        </div>
    )
}

export default ErrorElement;