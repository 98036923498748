import React from "react";
import * as S from "./style";

const Header = ({title, children}) => {
    return(
        <S.Header>
            <h2>{title}</h2>
            {children}
        </S.Header>
    )
}

export default Header;