import request from "../utils/request";
const fetchDepartments = async (page) => {
    try {
        const { data, status } = await request({
            url: `department?page=${page}`,
            method: "GET",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};
const deleteDepartment = async (id) => {
    try {
        const { data, status } = await request({
            url: `department/${id}`,
            method: "DELETE",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

export {
    fetchDepartments,
    deleteDepartment
};