import {Navigate, Outlet} from "react-router-dom";
import Login from "./components/Login";

const handleBool = () => {
    const token = window.sessionStorage.getItem("accessToken");
    if(token){
        return true;
    }else return false;
}
const useAuth = () => {
    const user = {loggedIn: handleBool()};
    return user && user.loggedIn;
}

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet/> : <Login/>;
}

export default ProtectedRoutes;

const handleAdminBool = () => {
    const role = window.sessionStorage.getItem("loggedAs");
    if(role === "admin"){
        return true;
    }else return false;
}
const useAdminAuth = () => {
    // console.log(handleBool());
    const user = {loggedIn: handleAdminBool()};
    return user && user.loggedIn;
}

export const ProtectedAdminRoutes = () => {
    const isAuth = useAdminAuth();
    return isAuth ? <Outlet/> : <Navigate to="/"/>;
}

const handleEmployeeBool = () => {
    const role = window.sessionStorage.getItem("loggedAs");
    if(role === "employee"){
        return true;
    }else return false;
}
const useEmployeeAuth = () => {
    // console.log(handleBool());
    const user = {loggedIn: handleEmployeeBool()};
    return user && user.loggedIn;
}

export const ProtectedEmployeeRoutes = () => {
    const isAuth = useEmployeeAuth();
    return isAuth ? <Outlet/> : <Navigate to="/"/>;
}
