import React, {createContext, useEffect, useState} from "react";
import { PusherProvider, initPusher } from "./providers/pusher.context";
import Modal from "react-modal";
import { Button } from "./components/Button";

import './App.css'

import Login from "./components/Login";
import ProtectedRoutes, {ProtectedEmployeeRoutes, ProtectedAdminRoutes} from "./ProtectedRoutes";

import PanelAdmin from "./components/PanelAdmin/index";
import PanelEmployee from "./components/PanelEmployee/index";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import Dashboard from "./components/PanelAdmin/Dashboard";
import Calendary from "./components/PanelAdmin/Calendary";
import CalendaryDeleted from "./components/PanelAdmin/Calendary/Deleted";
import CalendaryAbsences from "./components/PanelAdmin/Calendary/Absences";
import EditAbsence from "./components/PanelAdmin/Calendary/Absences/EditAbsence";
import Cases from "./components/PanelAdmin/Cases";
import Departments from "./components/PanelAdmin/Departments";
import Notifications from "./components/PanelAdmin/Notifications";
import NotifTemplate from "./components/PanelAdmin/Notifications/Template";
import EditNotifTemplate from "./components/PanelAdmin/Notifications/EditNotifTemplate";
import Admins from "./components/PanelAdmin/Admins";
import Employees from "./components/PanelAdmin/Employees";
import Settings from "./components/PanelAdmin/Settings";
import Raports from "./components/PanelAdmin/Raports";
import AddEmployees from "./components/PanelAdmin/Employees/AddEmployees/index";
import EditEmployee from "./components/PanelAdmin/Employees/EditEmployee/index";
import AddAdmin from "./components/PanelAdmin/Admins/AddAdmin";
import EditAdmin from "./components/PanelAdmin/Admins/EditAdmin";
import AddDepartment from "./components/PanelAdmin/Departments/AddDepartment";
import EditDepartment from "./components/PanelAdmin/Departments/EditDepartment";
import AddCase from "./components/PanelAdmin/Cases/AddCase";
import EditCase from "./components/PanelAdmin/Cases/EditCase";
import History from "./components/PanelAdmin/History";

import EmployeeCalendar from "./components/PanelEmployee/EmployeeCalendar";
import EmployeeAbsences from "./components/PanelEmployee/EmployeeAbsences";
import EditEmployeeAbsence from "./components/PanelEmployee/EmployeeAbsences/EditAbsence";
import EmployeeDeleted from "./components/PanelEmployee/EmployeeDeleted";
import EmployeeNotifications from "./components/PanelEmployee/EmployeeNotifications";
import EmployeeSettings from "./components/PanelEmployee/EmployeeSettings";
import AddNewReservation from "./components/PanelEmployee/EmployeeCalendar/AddNewReservation";
import ReservationView from "./components/PanelEmployee/EmployeeCalendar/ReservationView/ReservationView";
import EditReservation from "./components/PanelEmployee/EmployeeCalendar/EditReservation"
import ApplicationSettings from "./components/PanelAdmin/ApplicationSettings";

import ErrorElement from "./components/ErrorElement";

import { DayGrid } from './components/PanelAdmin/Calendary/DayGrid'
import { MonthGrid } from './components/PanelAdmin/Calendary/MonthGrid'
import SystemEditNotificationTemplate from "./components/PanelAdmin/SystemNotifications/EditNotifTemplate";
import SystemNotificatonTemplate from "./components/PanelAdmin/SystemNotifications";

const router = createBrowserRouter((
    createRoutesFromElements(
        <Route path="/">
        <Route path="/" errorElement={<ErrorElement/>} >
            <Route index element={<Login/>}/>
            <Route element={<ProtectedRoutes/>}>
                <Route element={<ProtectedAdminRoutes/>}>
                    <Route path="/panel-admin" element={<PanelAdmin/>}>
                        <Route index element={<Dashboard/>} />
                        <Route path="/panel-admin/kalendarze">
                            <Route path="/panel-admin/kalendarze/calendar" element={<Calendary/>} >
                                <Route path="/panel-admin/kalendarze/calendar/month" element={<MonthGrid/>} />
                                <Route path="/panel-admin/kalendarze/calendar/day" element={<DayGrid/>} />
                            </Route>
                            <Route path="/panel-admin/kalendarze/usuniete" element={<CalendaryDeleted/>} />
                            <Route path="/panel-admin/kalendarze/nieobecnosci" element={<CalendaryAbsences/>} />
                            <Route path="/panel-admin/kalendarze/nieobecnosci/edytuj" element={<EditAbsence/>} />
                            <Route path="/panel-admin/kalendarze/rezerwacja" element={<ReservationView />} />
                            <Route path="/panel-admin/kalendarze/edytuj" element={<EditReservation/>}/>
                            <Route path="/panel-admin/kalendarze/edytuj2" element={<EditAbsence/>}/>
                        </Route>
                        <Route path="/panel-admin/historia" element={<History/>} />
                        <Route path="/panel-admin/sprawy">
                            <Route index element={<Cases/>}/>
                            <Route path="/panel-admin/sprawy/dodaj" element={<AddCase/>}/>
                            <Route path="/panel-admin/sprawy/edytuj" element={<EditCase/>}/>
                        </Route>
                        <Route path="/panel-admin/wydzialy">
                                <Route index element={<Departments/>} />
                                <Route path="/panel-admin/wydzialy/dodaj" element={<AddDepartment/>} />
                                <Route path="/panel-admin/wydzialy/edytuj" element={<EditDepartment/>} />
                        </Route>
                        <Route path="/panel-admin/powiadomienia" >
                            <Route index element={<Notifications/>} />
                            <Route path="/panel-admin/powiadomienia/szablon" element={<NotifTemplate/>} />
                            <Route path="/panel-admin/powiadomienia/edytuj" element={<EditNotifTemplate/>} />
                        </Route>
                        <Route path="/panel-admin/powiadomienia-systemowe" >
                            <Route index element={<SystemNotificatonTemplate/>} />
                            <Route path="/panel-admin/powiadomienia-systemowe/edytuj" element={<SystemEditNotificationTemplate/>} />
                        </Route>
                        <Route path="/panel-admin/administratorzy" >
                            <Route index element={<Admins/>} />
                            <Route path="/panel-admin/administratorzy/dodaj" element={<AddAdmin/>} />
                            <Route path="/panel-admin/administratorzy/edytuj" element={<EditAdmin/>} />
                        </Route>
                        <Route path="/panel-admin/pracownicy">
                            <Route index element={<Employees/>}/>
                            <Route path="/panel-admin/pracownicy/dodaj" element={<AddEmployees/>} />
                            <Route path="/panel-admin/pracownicy/edytuj" element={<EditEmployee/>} />
                        </Route>
                        <Route path="/panel-admin/ustawienia" element={<Settings/>} />
                        <Route path="/panel-admin/raporty" element={<Raports/>} />
                        <Route path="/panel-admin/ustawienia-aplikacji" element={<ApplicationSettings/>} />
                    </Route>
                </Route>
                <Route element={<ProtectedEmployeeRoutes/>}>
                    <Route path="/panel-employee" element={<PanelEmployee/>} >
                        <Route path="/panel-employee/calendar" element={<EmployeeCalendar/>} >
                            <Route path="/panel-employee/calendar/month" element={<MonthGrid/>} />
                            <Route path="/panel-employee/calendar/day" element={<DayGrid/>} />
                        </Route>
                        <Route path="/panel-employee/historia" element={<History/>} />
                        <Route path="/panel-employee/rezerwacja" element={<ReservationView />} />
                        <Route path="/panel-employee/dodaj-rezerwacje" element={<AddNewReservation/>}/>
                        <Route path="/panel-employee/edytuj" element={<EditReservation/>}/>
                        <Route path="/panel-employee/edytuj2" element={<EditAbsence/>}/>
                        <Route path="/panel-employee/nieobecnosci">
                            <Route index element={<EmployeeAbsences/>} />
                            <Route path="/panel-employee/nieobecnosci/edytuj" element={<EditEmployeeAbsence/>} />
                        </Route>
                        <Route path="/panel-employee/usuniete" element={<EmployeeDeleted/>} />
                        <Route path="/panel-employee/powiadomienia" element={<EmployeeNotifications/>} />
                        <Route path="/panel-employee/ustawienia" element={<EmployeeSettings/>} />
                    </Route>
                </Route>
            </Route>
        </Route>
        </Route>
    )
));

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "430px",
        height: "150px",
        backgroundColor: "#fff",
        border: "none",
        position: "relative"
    },
    overlay:{
        backgroundColor: 'rgba(148,148,148,0.63)'
    }
};

export const LoadingContext = createContext(null);


function App() {
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [posMessage, setPosMessage] = useState('');

    const pusherClient = initPusher();

    useEffect(() => {
        pusherClient.subscribe('private-pos-action')
            .bind('pos-action', function (data) {
                // console.log("connected");
                setPosMessage(data.message);
                setIsOpen(true);
            }
        );
    });

    return (
        <PusherProvider pusher={pusherClient}>
            <LoadingContext.Provider value={[loading, setLoading]}>
                <RouterProvider router={router}/>
            </LoadingContext.Provider>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                contentLabel="POS Error"
                ariaHideApp={false}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <p>Uwaga! POS zgłosił bląd!</p>
                    <p>{posMessage}</p>
                    <div style={{display: "flex", width: "15rem", justifyContent: "center"}}>
                        <Button type="button" onClick={() => {
                            setIsOpen(false);
                        }} value="Ok"/>
                    </div>
                </div>
            </Modal>
        </PusherProvider>
    );
}

export default App;
