import React, {useState} from "react";
import * as S from "./style";
export const ListTitle = ({children}) => {
    return(
        <S.ListTitle>
            {children}
        </S.ListTitle>
    )
}
export const ListItem = ({children}) => {
    const [flag, setFlag] = useState(false);
    return(
        <S.ListItem onClick={() => setFlag(!flag)} className={flag && "active"}>
            {children}
        </S.ListItem>
    )
}

const List = ({children, style}) => {
    return(
        <S.List style={style}>
                {children}
        </S.List>
    )
}

export default List;