import React, {useEffect, useState} from "react";
import {Button} from "../../Button/index";
import Header from "../../Header/index";
import {MainContent} from "../../Layout/index";
import List, {ListItem, ListTitle} from "../../List/index";
import {ArrowDown, EditIcon, TrashIcon} from "../../../images/index";
import Pagination from "../../Pagination/index";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import {customStyles} from "../../modalStyles";
import * as S from "../../modalStyles"
import Modal from "react-modal";
import LoadingComponent from "../../Loading";
import {deleteUser, fetchUsers} from "../../../services/users.service";

const Employees = () => {
    const navigate = useNavigate();

    const [confirmationName, setConfirmationName] = useState('');
    const [confirmationId, setConfirmationId] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    function closeModal() {
        setIsOpen(false);
    }
    function openModal() {
        setIsOpen(true);
    }

    const [employees, setEmployees] = useState(null)
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetchUsers(page, 2)
            .then(res => {
                setLastPage(res.lastPage);
                setEmployees(res.items);
            })
            .catch(err => console.log(err));
    }, [page, refresh]);

    const handleDelete = async (id1, name) => {
        await deleteUser(id1)
            .then(() => {
                toast.success(`Pomyślnie usunięto ${name}`);
                setRefresh(!refresh);
            })
            .catch(err => {
                console.log(err);
                toast.error(`Błąd! Spróbuj ponownie`);
            })
    };

    return (
        <>
            <Header title="Pracownicy">
                <Button
                    value="Dodaj pracownika"
                    type="button"
                    style={{backgroundColor: "#CB444A", color: "#FFFFFF"}}
                    onClick={() => navigate("/panel-admin/pracownicy/dodaj")}
                />
            </Header>
            <MainContent>
                <List>
                    <ListTitle>
                        <div style={{width: "5%"}}>
                            <p>Id</p>
                            <img src={ArrowDown} alt="arror-down"/>
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Imię i nazwisko</p>
                            <img src={ArrowDown} alt="arror-down"/>
                        </div>
                        <div style={{width: "30%"}}>
                            <p>Wydział</p>
                            <img src={ArrowDown} alt="arror-down"/>
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Numer telefonu</p>
                            <img src={ArrowDown} alt="arror-down"/>
                        </div>
                        <div style={{width: "20%"}}>
                            <p>E-mail</p>
                            <img src={ArrowDown} alt="arror-down"/>
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Akcja</p>
                        </div>
                    </ListTitle>
                    {employees && employees.map(e =>
                        <ListItem key={e.id}>
                            <div style={{width: "5%"}}>
                                <p>{e.id}</p>
                            </div>
                            <div style={{width: "15%"}}>
                                <p>{e.name}</p>
                            </div>
                            <div style={{width: "30%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent:"center"}}>
                                {e.departments.map(d => <p key={d.id}>{d.name}</p>)}
                            </div>
                            <div style={{width: "15%"}}>
                                <p>{e.phone}</p>
                            </div>
                            <div style={{width: "20%"}}>
                                <p>{e.email}</p>
                            </div>
                            <div className="list_action_container" style={{width: "15%"}}>
                                {e.id.toString() !== localStorage.getItem('userId') &&
                                    <>
                                        <img
                                            src={EditIcon}
                                            alt="edit-icon"
                                            onClick={() => {
                                                navigate("/panel-admin/pracownicy/edytuj")
                                                localStorage.setItem("editedEmployee", e.id)
                                            }}
                                        />

                                        <img
                                            src={TrashIcon}
                                            alt="trash-icon"
                                            style={{marginLeft: ".5rem"}}
                                            onClick={() => {
                                                setConfirmationName(e.name);
                                                setConfirmationId(e.id);
                                                openModal();
                                            }}
                                        />
                                    </>
                                }
                            </div>
                        </ListItem>
                    )}
                </List>
                {!employees && <LoadingComponent/>}

            </MainContent>
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
            <ToastContainer/>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <S.DeletingConfirmation>
                    <div className="box">
                        <h4>Czy na pewno usunąć {confirmationName}?</h4>
                        <div className="buttons">
                            <button style={{backgroundColor: "#CB444A"}} onClick={() => {
                                closeModal();
                                handleDelete(confirmationId, confirmationName);
                            }}>Tak</button>
                            <button style={{backgroundColor: "#181818"}} onClick={() => closeModal()}>Nie</button>
                        </div>
                    </div>
                </S.DeletingConfirmation>
            </Modal>
        </>
    )
}

export default Employees;