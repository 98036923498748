import React, {useState, useEffect} from "react";
import axios from "axios";
import {Button, Input} from "../../../Button/index";
import Header from "../../../Header/index";
import {Form, MainContent} from "../../../Layout/index";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import List, {ListItem, ListTitle} from "../../../List/index";
import {ArrowDown} from "../../../../images/index";
import {url} from "../../../../utils/request";
import {useNavigate} from "react-router-dom";

const AddEmployees = () => {
    const navigate = useNavigate();

    const [departments, setDepartments] = useState(null);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [chosenDepartments, setChosenDepartments] = useState([]);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    useEffect(() => {
        axios.get(`${url}department/all`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setDepartments(res.data.items);
            })
            .catch(err => console.log(err))
    }, [])
    const addNewEmployee = (e) => {
        e.preventDefault();

        const newEmployee = {
            "name": name,
            "role_id": 2,
            "phone": phone,
            "email": email,
            "departments": chosenDepartments,
            "password": password,
            "password_confirmation": passwordConfirm
        }

        if(chosenDepartments.length){
            axios.post(`${url}user` , newEmployee, {
                headers: {
                    Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
                }
            })
                .then(res => {
                    toast.success(`Pomyślnie dodano pracownika`);
                    navigate("/panel-admin/pracownicy");
                })
                .catch(err => {
                    // console.log(err.response.data.errors)
                    toast.error(`Błąd! Spróbuj ponownie`);
                })
        } else {
            toast.error(`Błąd! Przydziel pracownikowi min 1 wydział`);
        }
    }
    const addDepartment = id => {
        if(chosenDepartments.find(el => el === id)){
            setChosenDepartments(chosenDepartments.filter(el => el !== id));
        }else{
            setChosenDepartments([...chosenDepartments, id]);
        }
    }

    return(
        <>
            <Header title="Dodaj pracownika" />
            <MainContent>
                <Form onSubmit={addNewEmployee}>
                    <div style={{maxWidth: "20rem"}}>
                        <Input
                            type="text"
                            placeholder="Imię i nazwisko"
                            style={{width: "20rem"}}
                            onChange={e => setName(e.target.value)}
                            value={name}
                        />
                        <Input
                            type="text"
                            placeholder="E-mail"
                            style={{width: "20rem"}}
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                        <Input
                            type="text"
                            placeholder="Telefon"
                            style={{width: "20rem"}}
                            onChange={e => setPhone(e.target.value)}
                            value={phone}
                        />
                        <Input
                            type="password"
                            placeholder="Hasło"
                            style={{width: "20rem"}}
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                        />
                        <Input
                            type="password"
                            placeholder="Potwierdź hasło"
                            style={{width: "20rem"}}
                            onChange={e => setPasswordConfirm(e.target.value)}
                            value={passwordConfirm}
                        />
                    </div>
                    <h4>Przydziel do wydziałów</h4>
                    <List style={{margin: "-4rem 0 2.5rem 0", width: "100%"}}>
                        <ListTitle>
                            <div style={{width: "5%"}}>
                                <p>Dodaj</p>
                            </div>
                            <div style={{width: "95%"}}>
                                <p>Nazwa</p>
                                <img
                                    src={ArrowDown}
                                    alt="arrow-down"
                                />
                            </div>
                        </ListTitle>
                        {departments && departments.map( d =>
                            <ListItem key={d.id}>
                                <div style={{width: "5%"}}>
                                    <input type="checkbox" onChange={() => addDepartment(d.id)}/>
                                </div>
                                <div style={{width: "95%"}}>
                                    <p>{d.name}</p>
                                </div>
                            </ListItem>
                        )}
                    </List>
                    <Button
                        type="submit"
                        value="Zapisz"
                    />
                </Form>
            </MainContent>
            <ToastContainer/>
        </>
    )
};

export default AddEmployees;
