import styled from "styled-components";

export const List = styled.ul`
    border: 1px solid #F0F2F3;
    border-radius: .5rem;
    height: auto;
    width: calc(100% - 5.2rem);
    top: 4.4rem;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    list-style: none;
    font-family: "Open Sans", serif;
    padding: 0;
    margin-bottom: 150px;
    li:nth-child(even) {
      background: #F5F5F5;
    }
  //.active{
  //  div{
  //    .box_children{
  //      display: flex;
  //      flex-direction: column;
  //      align-items: flex-start;
  //      height: auto;
  //      margin: 1rem 0 1rem -1rem;
  //      p{
  //        font-size: 12px;
  //        color: #8C8C8C;
  //      }
  //    }
  //  }
  //}
  
`;
export const ListTitle = styled.li`
  width: 100%;
  display: flex;
  height: 3rem;
  div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    p{
      font-size: .75rem;
      font-weight: 600;
      color: #808080;
      margin-bottom: -10px;
    }
    img{
      cursor: pointer;
      margin-bottom: -22px;
    }
  }
`;
export const ListItem = styled.li`
  width: 100%;
  display: flex;
  min-height: 3rem;
  height: auto;
  border-top: 1px solid #F0F2F3;
  div{
    display: flex;
    //align-items: center;
    padding: 0 1rem;
    p:first-child{
      margin-top: 1rem;
      font-size: .75rem;
      font-weight: 400;
      color: #000000;
    }
    P{
      padding: 0;
      margin: 0;
      font-size: .75rem;
      font-weight: 400;
      color: #000000;
    }
    p:last-child{
      margin-bottom: 1rem;
    }
    .notif-send-btn{
      color: #CB444A;
      position: absolute;
      right: 1rem;
      margin-top: 1rem;
      background-color: transparent;
      border: none;
      font-weight: 600;
      cursor: pointer;
    }
    .box_children{
      width: 100%;
      flex-direction: column;
      position: relative;
      top: 20px;
      left: -22px;
      margin-bottom: 10px;
            p{
              font-size: 12px;
              color: #8C8C8C;
            }
    }
  }
  .list_action_container{
    justify-content: flex-end;
    height: 100%;
    img{
      margin-top: 12px;
      width: 1.4rem;
      height: 100%;
      cursor: pointer;
    }
  }
`;
