import React from 'react';
import styled from "styled-components";
import Loading from '../images/loading.gif';

const LoadingBox = styled.div`
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
      img{
        width: 50px;
        height: auto;
      }
  
`;

const LoadingComponent = ({style}) => {
    return (
        <LoadingBox style={style}>
            <img src={Loading} alt="loading"/>
        </LoadingBox>
    )
}

export default LoadingComponent;