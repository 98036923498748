import React, {useState, useEffect} from "react";
import axios from "axios";
import Header from "../../../Header/index";
import {Form, MainContent} from "../../../Layout/index";
import {Button, Input} from "../../../Button/index";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {url} from "../../../../utils/request";
import {useNavigate} from "react-router-dom";

const EditAdmin = () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    useEffect(() => {
        axios.get(`${url}user/${localStorage.getItem("editedAdmin")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setName(res.data.user.name);
                setEmail(res.data.user.email);
                setPhone(res.data.user.phone);
            })
            .catch(err => console.log(err));
    }, [])

    const editAdmin = (e) => {
        e.preventDefault();
        const value = {
            "name": name,
            "role_id": 1,
            "phone": phone,
            "email": email,
            "password": password,
            "password_confirmation": passwordConfirmation
        }

        axios.put(`${url}user/${localStorage.getItem("editedAdmin")}` , value , {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success("Pomyślnie edytowano administratora");
                navigate('/panel-admin/administratorzy');
            })
            .catch(() => toast.error("Błąd! Spróbuj ponownie"))
    };

    return(
        <>
            <Header title="Edytuj administratora" />
            <MainContent>
                <Form onSubmit={editAdmin}>
                    <div style={{maxWidth: "20rem"}}>
                        <h4>Imię i nazwisko</h4>
                        <Input
                            type="text"
                            placeholder="Imię i nazwisko"
                            style={{width: "20rem"}}
                            onChange={e => setName(e.target.value)}
                            value={name}
                        />
                        <h4>Adres email</h4>
                        <Input
                            type="text"
                            placeholder="E-mail"
                            style={{width: "20rem"}}
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                        <h4>Nr telefonu</h4>
                        <Input
                            type="text"
                            placeholder="Telefon"
                            style={{width: "20rem"}}
                            onChange={e => setPhone(e.target.value)}
                            value={phone}
                        />
                        <h4>Nadaj nowe hasło</h4>
                        <Input
                            type="password"
                            placeholder="Nowe hasło"
                            style={{width: "20rem"}}
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                        />
                        <Input
                            type="password"
                            placeholder="Potwierdz nowe hasło"
                            style={{width: "20rem"}}
                            onChange={e => setPasswordConfirmation(e.target.value)}
                            value={passwordConfirmation}
                        />
                    </div>
                    <Button
                        type="submit"
                        value="Zapisz"
                    />
                </Form>
            </MainContent>
            <ToastContainer/>
        </>
    )
};

export default EditAdmin;