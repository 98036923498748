import styled from "styled-components";

export const Layout = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #F5F5F5;
  position: relative;
`;

export const Main = styled.div`
  height: 100vh;
  width: calc(100% - 14.3rem - 2rem);
  position: absolute;
  right: 0;
  margin-right: 1rem;
`;
export const MainContent = styled.div`
  width: 100%;
  height: calc(100% - 7rem);
  background-color: #FFFFFF;
  border-radius: .75rem;
  overflow: auto;
`;
export const HalfMainContent = styled.div`
  height: 100%;
  background-color: #FFFFFF;
  border-radius: .75rem;
  overflow: auto;
  position: relative;
  h3{
    margin-top: 1.5rem;
    font-size: 20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
  }
`;
export const Form = styled.form`
  position: relative;
  top: 3.75rem;
  margin: 0 2rem ;
  h4{
    font-size: 14px;
    margin: .5rem 0 .5rem .75rem;
  } 
  label{
    font-family: "Montserrat", serif;
    font-size: 14px;
    margin-left: 5px;
  }
  input[type="submit"]{
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 3.25rem 0;
  }
`;