import request from "../utils/request";
const fetchAdminsNotifications = async (page) => {
    try {
        const { data, status } = await request({
            url: `notification?page=${page}`,
            method: "GET",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const showAdminsNotification = async (id) => {
    try {
        const { data, status } = await request({
            url: `notification/${id}`,
            method: "GET",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const showEmployeeNotification = async (notifId, id) => {
    try {
        const { data, status } = await request({
            url: `employee/notification/${notifId}?department_id=${id}`,
            method: "GET",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const fetchTemplates = async (page, type = 'user') => {
    try {
        const { data, status } = await request({
            url: `notificationSchema?page=${page}`,
            method: "GET",
            params: {
                type: type
            }
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const fetchAllTemplates = async (id, type = 'user') => {
    try {
        const { data, status } = await request({
            url: `notificationSchema/all?department_id=${id}?type=${type}`,
            method: "GET",
            params: {
                type: type
            }
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const deleteTemplate = async (id) => {
    try {
        const { data, status } = await request({
            url: `notificationSchema/${id}`,
            method: "DELETE",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const fetchEmployeeNotif = async (params) => {
    try {
        const { data, status } = await request({
            url: `employee/notification`,
            method: "GET",
            params: params
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};


export {
    fetchAdminsNotifications,
    showAdminsNotification,
    fetchTemplates,
    deleteTemplate,
    fetchEmployeeNotif,
    fetchAllTemplates,
    showEmployeeNotification
};