import request from "../utils/request";

const fetchAbsences = async (page, id) => {
    try {
        const { data, status } = await request({
            url: `absent?page=${page}&department_id=${id}`,
            method: "GET",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const deleteAbsence = async (absenceId, id) => {
    try {
        const { data, status } = await request({
            url: `absent/${absenceId}?department_id=${id}`,
            method: "DELETE",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const showAbsence = async (absenceId, id) => {
    try {
        const { data, status } = await request({
            url: `absent/${absenceId}?department_id=${id}`,
            method: "GET",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

export {
    fetchAbsences,
    deleteAbsence,
    showAbsence
};