import React, {useEffect, useState} from "react";
import Header from "../../Header/index";
import List from "../../List/index";
import {ListTitle, ListItem} from "../../List/index";
import {Button} from "../../Button/index";
import {ArrowDown, EditIcon, TrashIcon} from "../../../images/index";
import Pagination from "../../Pagination/index";
import {MainContent} from "../../Layout/index";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import Modal from "react-modal";
import {customStyles} from "../../modalStyles";
import * as S from "../../modalStyles"
import LoadingComponent from "../../Loading";
import {fetchDepartments, deleteDepartment} from "../../../services/departments.service";

const Departments = () => {
    const navigate = useNavigate();

    const [items, setItems] = useState(null);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const [refresh, setRefresh] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [confirmationName, setConfirmationName] = useState('');
    const [confirmationId, setConfirmationId] = useState('');

    function closeModal() {
        setIsOpen(false);
    }
    function openModal() {
        setIsOpen(true);
    }

    useEffect(() => {
        fetchDepartments(page)
            .then(res => {
                setLastPage(res.lastPage);
                setItems(res.items);
            })
            .catch(err => console.log(err));
    }, [page, refresh]);

    const handleDelete = async (id1, name) => {
        await deleteDepartment(id1)
            .then(() => {
                toast.success(`Pomyślnie usunięto ${name}`);
                setRefresh(!refresh);
            })
            .catch(err => {
                toast.error(`Błąd! Spróbuj ponownie`);
            })
    }

    return(
        <>
            <Header title="Wydziały">
                <Button
                    value="Dodaj wydział"
                    type="button"
                    style={{backgroundColor: "#CB444A", color: "#FFFFFF"}}
                    onClick={() => navigate("/panel-admin/wydzialy/dodaj")}
                />
            </Header>
            <MainContent>
                <List>
                    <ListTitle>
                        <div style={{width: "55%"}}>
                            <p>Nazwa</p>
                            <img src={ArrowDown} alt="arrow-down"/>
                        </div>
                        <div style={{width: "30%"}}>
                            <p>Ilość stanowisk</p>
                            <img src={ArrowDown} alt="arrow-down"/>
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Akcja</p>
                        </div>
                    </ListTitle>
                        {items && items.map( item =>
                            <ListItem key={item.id}>
                                <div style={{width: "55%"}}>
                                    <p>{item.name}</p>
                                </div>
                                <div style={{width: "30%"}}>
                                    <p>{item.counters_count}</p>
                                </div>
                                <div className="list_action_container" style={{width: "15%"}}>
                                    <img
                                        src={EditIcon}
                                        alt="edit-icon"
                                        onClick={() => {
                                            localStorage.setItem("editedDepartmentId", item.id)
                                            navigate("/panel-admin/wydzialy/edytuj")
                                        }}
                                    />
                                    <img
                                        src={TrashIcon}
                                        alt="trash-icon"
                                        style={{marginLeft: ".5rem"}}
                                        onClick={() => {
                                            setConfirmationName(item.name);
                                            setConfirmationId(item.id);
                                            openModal();
                                        }}
                                    />
                                </div>
                            </ListItem>
                        )}
                </List>
                {!items && <LoadingComponent/>}
            </MainContent>
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
            <ToastContainer/>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <S.DeletingConfirmation>
                    <div className="box">
                        <h4>Czy na pewno usunąć {confirmationName}?</h4>
                        <div className="buttons">
                            <button style={{backgroundColor: "#CB444A"}} onClick={() => {
                                closeModal();
                                handleDelete(confirmationId, confirmationName);
                            }}>Tak</button>
                            <button style={{backgroundColor: "#181818"}}  onClick={() => closeModal()}>Nie</button>
                        </div>
                    </div>
                </S.DeletingConfirmation>
            </Modal>
        </>
    )
}

export default Departments;