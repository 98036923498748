import React from 'react';
import ReactPaginate from 'react-paginate';
import * as S from "./style";
import {PaginationRight, PaginationLeft} from "../../images/index";

function Pagination({setPage, lastPage, style}) {
    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };

    const NextLabel = () => (
        <>
            <p>Następna</p>
            <div className="label_img_box">
                <img src={PaginationRight} alt="right-arrow"/>
            </div>
        </>
    );
    const PrevLabel = () => (
        <>
            <div className="label_img_box">
                <img src={PaginationLeft} alt="left-arrow"/>
            </div>
            <p>Poprzednia</p>
        </>
    );

    return (
        <S.Pagination style={style}>
            <ReactPaginate
                breakLabel="..."
                nextLabel={<NextLabel />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={lastPage}
                previousLabel={<PrevLabel />}
                renderOnZeroPageCount={null}
                containerClassName="pagination_container"  //all
                pageLinkClassName="pagination_pages" //numery stron
                activeLinkClassName="pagination_pages_active" //aktywna strona
                previousClassName="pagination_nav"  //nav
                nextClassName="pagination_nav"  //nav
                previousLinkClassName="pagination_nav_a"
                nextLinkClassName="pagination_nav_a"
            />
        </S.Pagination>
    );
}

export default Pagination;