import React, {useState, useEffect} from "react";
import axios from "axios";
import {url} from "../../../../utils/request";
import Header from "../../../Header";
import {HalfMainContent, MainContent, Form} from "../../../Layout";
import * as S from '../../style';
import {Input, Button} from "../../../Button";
import List, {ListItem, ListTitle} from "../../../List";
import {ArrowDown, EditIcon, TrashIcon} from "../../../../images";
import Pagination from "../../../Pagination";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import {customStyles} from "../../../modalStyles";
import * as Q from "../../../modalStyles"
import Modal from "react-modal";
import LoadingComponent from "../../../Loading";
import {fetchAllCounters} from "../../../../services/counters.service";
import {fetchAbsences} from "../../../../services/absences.service";

const CalendaryAbsences = () => {
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const [selectedDepId, setSelectedDepId] = useState(JSON.parse(localStorage.getItem("selectedDepId")));
    const [departments, setDepartments] = useState((JSON.parse(localStorage.getItem("departments"))));
    const [absences, setAbsences] = useState(null);
    const [positions, setPositions] = useState([]);

    const [dateFrom, setDateFrom] = useState('');
    const [timeFrom, setTimeFrom] = useState('');
    const [timeTo, setTimeTo] = useState('');
    const [selectedPositionId, setSelectedPositionId] = useState();

    const [isOpen, setIsOpen] = useState(false);

    const [confirmationId, setConfirmationId] = useState('');

    function closeModal() {
        setIsOpen(false);
    }
    function openModal() {
        setIsOpen(true);
    }


    useEffect(() => {
        localStorage.setItem("selectedDepId", selectedDepId.toString());
    }, [selectedDepId]);


    useEffect(() => {
        fetchAbsences(page, selectedDepId)
            .then(res => {
                setAbsences(res.items);
                setLastPage(res.lastPage);
            })
            .catch(err => console.log(err))
        fetchAllCounters(selectedDepId)
            .then(res => {
                setPositions(res.items);
            })
            .catch(err => console.log(err));
    }, [page, selectedDepId, refresh]);

    const fetchAbsenceData = (id) => {
        axios.get(`${url}counter/all?department_id=${id}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => setPositions(res.data.items))
            .catch(err => console.log(err))
    }
    const addAbsence = (e) => {
        e.preventDefault();

        const value = {
            "counter_id": selectedPositionId,
            "from": `${dateFrom} ${timeFrom}`,
            "to": `${dateFrom} ${timeTo}`,
            "department_id": selectedDepId
        }

        axios.post(`${url}absent`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success("Pomyślnie dodano nieobecność")
                setDateFrom('');
                setTimeFrom('');
                setTimeTo('');
                setRefresh(!refresh);
            })
            .catch((err) => {
                console.log(err);
                if(err.response.data.message.slice(0, 3) === 'Can'){
                    toast.error(`Błąd! W tym czasie są wizyty`)
                } else toast.error(`Błąd! Uzupełnij dane prawidłowo`)
            })
    }

    const deleteAbsence = (id1) => {
        axios.delete(`${url}absent/${id1}?department_id=${selectedDepId}` , {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success("Pomyślnie usunięto nieobecność")
                setRefresh(!refresh);
            })
            .catch(() => toast.error("Błąd! Spróbuj ponownie"))

    }

    return(
        <>
            <Header title="Nieobecności">
                <div className="select-wrapper">
                    <select onChange={(e) => {
                        fetchAbsenceData(e.target.value);
                        setSelectedDepId(e.target.value);
                    }} name="" id="" >
                        {departments && departments.map(d => (
                            <option selected={d.id === selectedDepId} key={d.id} value={d.id}>{d.name}</option>
                        ))}
                    </select>
                </div>
            </Header>
                <S.CalendaryAbsences>
                    <HalfMainContent style={{width: "calc(40% - .5rem)"}}>
                        <Form onSubmit={addAbsence}>
                            <div style={{maxWidth: "20rem"}}>
                                <div className="select-wrapper">
                                    <select onChange={(e) => setSelectedPositionId(e.target.value)} name="" id="" >
                                        <option disabled selected value="">Stanowisko</option>
                                        {positions && positions.map(p => (
                                            <option key={p.id} value={p.id}>{p.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <h4>Data</h4>
                                <Input
                                    type="date"
                                    onChange={e => setDateFrom(e.target.value)}
                                    value={dateFrom}
                                    style={{width: "20rem"}}
                                />
                                <h4>Godz od</h4>
                                <Input
                                    type="time"
                                    onChange={e => setTimeFrom(e.target.value)}
                                    value={timeFrom}
                                    style={{width: "20rem"}}
                                />
                                <h4>Godz do</h4>
                                <Input
                                    type="time"
                                    onChange={e => setTimeTo(e.target.value)}
                                    value={timeTo}
                                    style={{width: "20rem"}}
                                />
                            </div>
                            <Button
                                type="submit"
                                value="Zapisz"
                            />
                        </Form>
                    </HalfMainContent>
                    <HalfMainContent style={{marginLeft: "1rem", width: "calc(60% - .5rem)", position: "relative"}}>
                        <h3>Lista nieobecności</h3>
                        <List>
                            <ListTitle>
                                <div style={{width: "50%"}}>
                                    <p>Data</p>
                                    <img src={ArrowDown} alt="arrow-down"/>
                                </div>
                                <div style={{width: "35%"}}>
                                    <p>Stanowisko</p>
                                    <img src={ArrowDown} alt="arrow-down"/>
                                </div>
                                <div style={{width: "15%"}}>
                                    <p>Akcja</p>
                                </div>
                            </ListTitle>
                            {absences && absences.map( a =>
                                <ListItem key={a.id}>
                                    <div style={{width: "50%"}}>
                                        <p>{a.from}</p>
                                    </div>
                                    <div style={{width: "35%"}}>
                                        <p>{a.counter}</p>
                                    </div>
                                    <div className="list_action_container" style={{width: "15%"}}>
                                        <img
                                            src={EditIcon}
                                            alt="edit-icon"
                                            onClick={() => {
                                                localStorage.setItem("editedAbsenceId", a.id);
                                                localStorage.setItem("editedAbsenceDepId", selectedDepId);
                                                navigate("/panel-admin/kalendarze/nieobecnosci/edytuj");
                                            }}
                                        />
                                        <img
                                            src={TrashIcon}
                                            alt="trash-icon"
                                            style={{marginLeft: ".5rem"}}
                                            onClick={() => {
                                                setConfirmationId(a.id);
                                                openModal();
                                            }}
                                        />
                                    </div>
                                </ListItem>
                            )}
                        </List>
                        {!absences && <LoadingComponent />}
                        <Pagination style={{position: "absolute", top: "90%", margin: "0", width: "100%"}} page={page} setPage={setPage} lastPage={lastPage} />
                    </HalfMainContent>
                </S.CalendaryAbsences>
            <ToastContainer/>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <Q.DeletingConfirmation>
                    <div className="box">
                        <h4>Czy na pewno usunąć?</h4>
                        <div className="buttons">
                            <button style={{backgroundColor: "#CB444A"}} onClick={() => {
                                closeModal();
                                deleteAbsence(confirmationId);
                            }}>Tak</button>
                            <button style={{backgroundColor: "#181818"}}  onClick={() => closeModal()}>Nie</button>
                        </div>
                    </div>
                </Q.DeletingConfirmation>
            </Modal>
        </>
    )
};

export default CalendaryAbsences;