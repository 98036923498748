import styled from "styled-components";

export const NotifTemplate = styled.div`
  display: flex; 
  height: calc(100% - 7rem);
  textarea{
    font-family: "Montserrat", serif;
    border: none;
    border-radius: 30px;
    padding: 16px 30px;
    background-color: #F0F0F3;
    font-size: 13px;
    outline: none;
    margin-bottom: .6rem;
    width: calc(100% - 3.5rem);
    min-height: 250px;
    ::placeholder {
      color: #000000;
      font-size: 13px;
    }
  }
  .select-wrapper-notify {
    position: relative;
    select {
      font-family: "Montserrat", serif;
      border: none;
      border-radius: 30px;
      padding: 16px 30px;
      background-color: #F0F0F3;
      font-size: 13px;
      outline: none;
      margin-bottom: .6rem;
      width: 20rem;
      border-right: 2rem solid transparent;
    }
  }
`;

export const CalendaryAbsences = styled.div`
  display: flex;
  height: calc(100% - 7rem);
  .select-wrapper {
    position: relative;
    font-family: "Montserrat", serif;
    select {
      font-family: "Montserrat", serif;
      border: none;
      border-radius: 30px;
      padding: 16px 30px;
      background-color: #F0F0F3;
      font-size: 13px;
      outline: none;
      margin-bottom: .6rem;
      width: 20rem;
      border-right: 2rem solid transparent;
    }
  }
`;

export const DeletedCalendar = styled.div`
  .search-input {
    position: relative;
    input[type=search] {
      font-family: "Montserrat", serif;
      border: none;
      border-radius: 30px;
      padding: 16px 30px;
      background-color: #F0F0F3;
      font-size: 13px;
      outline: none;
      position: absolute;
      top: .5rem;
      right: 2.5rem;
      width: 20rem;
      ::placeholder {
        color: #000000;
        font-size: 13px;
      }
    }
    /* clears the ‘X’ from Internet Explorer */
    input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
    /* clears the ‘X’ from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
    img{
      position: absolute;
      top: 1.4rem;
      right: 3.5rem;
      cursor: pointer;
    }
  }
`;
export const Calendar = styled.div`
  margin: 2rem 2rem;
  .calendar-change-btns{
    margin-left: -.5rem;
    position: relative;
    z-index: 99;
    a{
      cursor: pointer;
    }
    input{
      border: none;
      background-color: transparent;
      font-size: .8rem;
      color: #979797;
      font-weight: 500;
      margin: 0 .5rem;
      cursor: pointer;
    }
    .active{
      border: 1px solid #979797;
      border-radius: 5px;
      padding: .5rem .8rem;
      cursor: pointer;
    }
    .active > input{
      color: #000;
    }
  }
  .fc-toolbar-chunk{
    display: flex;
    flex-direction: column;
    margin-top: -30px;
    align-items: center;
  }
  .fc .fc-button-primary:disabled{
    margin-bottom: 30px;
    border-color: transparent;
    position: relative;
    top: -11px;
    font-size: .8rem;
  }
  .fc .fc-button:not(:disabled){
    margin-bottom: 30px;
    border-color: transparent;
    position: relative;
    top: -11px;
    font-size: .8rem;
  }
  .fc-toolbar-title{
    padding-top: 40px;
  }
  .fc .fc-view-harness{
    margin-top: -40px;
  }
  .fc .fc-toolbar-title{
    font-size: 1.3rem;
  }
  .fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 1.2rem 4px;
    font-size: .9rem;
    font-weight: 500;
  }
  .fc .fc-button-primary{
    background-color: transparent;
    border-color: #ECECF2;
    border-radius: 10px;
  }
  .fc .fc-button-primary{
    color: #8181A5;
  }
  .fc .fc-button-group{
    margin-top: -20px;
  }
  .calendar-nav{
    margin-right: 6rem;
    input{
      margin: 0 .3rem;
    }
  }
  .time-line-content{
    background-color: #fff;
    height: 2rem;
  }
  .fc-event-main{
    height: 60px;
    padding: 1px 0 0 10px;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
  }
  .fc-event-title{
    max-height: 20px;
    white-space: normal;
  }
  .fc-scrollgrid-sync-inner span{
    display: inline-block;
  }
  .fc .pos::after {
    content: "POS";
    display: block;
    color: #CB444A;
    font-weight: 600;
  }
`;

export const QuickModal = styled.div`
  height: auto;
  padding: .5rem 0;
  background-color: #fff;
  border: 2px solid #D8D8D8;
  border-radius: 10px;
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button{
    padding-right: 10px;
    width: auto;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #646262;
    font-weight: 900;
    margin-top: 10px;
    :hover{
      color: #231F20;
    }
  }
`;

export const SecondModal = styled.div`
    width: auto;
    height: 90px;
    background-color: #fff;
    border: 2px solid #D8D8D8;
    border-radius: 10px;
    position: absolute;
    z-index: 99;
    display: flex;
    flex-direction: column;
      button{
        border: none;
        background-color: transparent;
        cursor: pointer;
        text-align: start;
        color: #646262;
        font-weight: 900;
        margin-top: 10px;
      }
`;
export const DeleteModal = styled.div`
    width: 100px;
    height: 90px;
    background-color: #fff;
    border: 2px solid #D8D8D8;
    border-radius: 10px;
    position: absolute;
    z-index: 99;
      h5{
        margin-left: 16px;
      }
      button{
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: #646262;
        font-weight: 900;
        //margin-top: 10px;
      }
`;


















