import React, {useState} from "react";
import * as S from "./style";
import Logo from "../../images/logo_jarocin 1.png";
import {
    DashboardIcon,
    CalendaryIcon,
    CasesIcon,
    DepartmentsIcon,
    NotificationsIcon,
    AdminsIcon,
    WorkersIcon,
    SettingsIcon,
    RaportsIcons,
    AndroidIcon,
    TrashNavIcon,
    HistoryIcon,
    SystemNotification
} from "../../images/index";
import {Button} from "../Button/index";
import {NavLink, Link,  useNavigate} from "react-router-dom";

const Navigation = () => {
    const navigate = useNavigate();

    const role = localStorage.getItem("userRole");

    const adminNavigationElements = [
        {id: 1, title: "Dashboard", slug: "", icon: DashboardIcon, secondNav: null},
        {id: 2, title: "Kalendarze",slug: "kalendarze/calendar/day", icon: CalendaryIcon, secondNav: null},
        {id: 11, title: "Historia rezerwacji",slug: "historia", icon: HistoryIcon, secondNav: null},
        {id: 3, title: "Sprawy",slug: "sprawy", icon: CasesIcon, secondNav: null},
        {id: 4, title: "Wydziały",slug: "wydzialy", icon: DepartmentsIcon, secondNav: null},
        {id: 5, title: "Powiadomienia",slug: "powiadomienia", icon: NotificationsIcon, secondNav: [{id: 10, title: "Lista powiadomień",slug: "powiadomienia"}, {id: 11, title: "Szablony",slug: "powiadomienia/szablon"}]},
        {id: 11, title: "Powiadomienia Systemowe", slug: "powiadomienia-systemowe", icon: SystemNotification, secondNav: null},
        {id: 6, title: "Administratorzy",slug: "administratorzy", icon: AdminsIcon, secondNav: null},
        {id: 7, title: "Pracownicy",slug: "pracownicy", icon: WorkersIcon, secondNav: null},
        {id: 8, title: "Ustawienia",slug: "ustawienia", icon: SettingsIcon, secondNav: null},
        {id: 9, title: "Ustawienia aplikacji", slug: "ustawienia-aplikacji", icon: AndroidIcon, secondNav: null},
        {id: 10, title: "Raporty",slug: "raporty", icon: RaportsIcons, secondNav: null},
    ]

    const employeeNavigationElements = [
        {id: 1, title: "Kalendarze",slug: "calendar/day", icon: CalendaryIcon},
        {id: 6, title: "Historia rezerwacji",slug: "historia", icon: HistoryIcon, secondNav: null},
        {id: 2, title: "Nieobecności",slug: "nieobecnosci", icon: AdminsIcon},
        {id: 3, title: "Usunięte",slug: "usuniete", icon: TrashNavIcon},
        {id: 4, title: "Powiadomienia",slug: "powiadomienia", icon: NotificationsIcon},
        {id: 5, title: "Ustawienia",slug: "ustawienia", icon: SettingsIcon},
    ]

    const [showSecNav, setShowSecNav] = useState(false);

    const show = id => {
        if(id === 5){
            setShowSecNav(true)
        }else{
            setShowSecNav(false)
        }
    }

    const logOut = () => {
        localStorage.clear();
        window.sessionStorage.clear();
        navigate("/");
    };

    return(
        <S.Navigation>
            <img src={Logo} alt="Logo Jarocina" className="navigation_logo"/>
            <nav>
                <ul>
                    {role === "1" ?
                        adminNavigationElements.map(el => (
                            <>
                                <NavLink onClick={() => show(el.id)} to={"/panel-admin/" + el.slug} key={el.id}>
                                    <li>
                                        <div className="a-container">
                                            <img src={el.icon} alt={el.title}/>
                                            <p>{el.title}</p>
                                        </div>
                                        <div className="border"></div>
                                    </li>
                                </NavLink>
                                {showSecNav && el.secondNav && el.secondNav.map(secEl =>
                                    <div key={secEl.id} className="second_nav">
                                        <Link to={secEl.slug}>{secEl.title}</Link>
                                    </div>
                                )
                                }
                            </>
                            ))
                        :
                        employeeNavigationElements.map(el => (
                                <NavLink to={"/panel-employee/" + el.slug} key={el.id}>
                                    <li>
                                        <div className="a-container">
                                            <img src={el.icon} alt={el.title}/>
                                            <p>{el.title}</p>
                                        </div>
                                        <div className="border"></div>
                                    </li>
                                </NavLink>
                            ))
                    }
                </ul>
            </nav>
            <Button
                type="button"
                value="Wyloguj"
                onClick={logOut}
                className="navigation_logout_button"
            />
        </S.Navigation>
    )
}
export default Navigation;