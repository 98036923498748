import React, {useState, useEffect} from "react";
import axios from "axios";
import Header from "../../Header/index";
import {MainContent} from "../../Layout/index";
import LineChart from "./lineChart";
import * as S from "./style";

const Dashboard = () => {

    const toDateFormat = date => {
        if(date == null)
        {return null;}
        return(
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1)
                .toString()
                .padStart(2, "0") +
            "-" +
            date.getDate()
                .toString()
                .padStart(2, "0")
        )
    };

    const getFirstDayOfWeek = date => {
        const temp = new Date(date.getTime());
        temp.setHours(0, 0, 0, 0, 0);
        temp.setDate(temp.getDate() - temp.getDay() + 1);
        return temp;
    };
    const getFridayDateInThisWeek = date => {
        const temp = new Date(date.getTime());
        temp.setHours(0, 0, 0, 0, 0);
        temp.setDate(temp.getDate() - temp.getDay() + 5);
        return temp;
    };

    // const [data1, setData1] = useState([]);
    const [data, setData] = useState(null);


    useEffect(() => {
        const date = new Date();
        const mondayInThisWeek = getFirstDayOfWeek(date);
        const fridayInThisWeek = getFridayDateInThisWeek(date);
        axios.get(`https://api-ewizyty.wsparcie-jarjarocin.pl/api/V1/panel/dashboard?from=${toDateFormat(mondayInThisWeek)}&to=${toDateFormat(fridayInThisWeek)}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setData({
                    labels: res.data.items.map(d => d.date),
                    datasets: [{
                        label: null,
                        data: res.data.items.map(d => d.reservations),
                        // data: [1, 2,4,5,6],
                        borderColor: '#CB444A',
                        tension: .5,
                        gradient: {
                            backgroundColor: {
                                axis: 'y',
                                colors: {
                                    0: '#fff',
                                    100: '#e5e5fb'
                                }
                            }
                        },
                        fill: true,
                        borderWidth: 3,
                        // pointBackgroundColor: '#CB444A',
                        pointBackgroundColor: '#EF959A',
                        pointBorderColor: '#EF959A',
                        pointBorderWidth: 10,
                        pointHoverBorderWidth: 15,
                        // pointWidth: 5
                    }]
                });
            })
            .catch(err => console.log(err))
    },[]);

    return(
        <>
            <Header title="Dashboard"/>
            <MainContent style={{display: "flex"}}>
                    <S.ChartContainer>
                        <h3>Wizyty w tym tygodniu</h3>
                        {data &&
                            <LineChart
                                chartData={data}
                            />
                        }
                        <ul>
                            <li>Pn</li>
                            <li>Wt</li>
                            <li>Śr</li>
                            <li>Cz</li>
                            <li>Pt</li>
                        </ul>
                    </S.ChartContainer>
            </MainContent>
        </>
    )
}
export default Dashboard;

