import React, {useState, useEffect} from "react";
import axios from 'axios';
import {url} from "../../../../utils/request";
import Header from "../../../Header";
import {MainContent, Form} from "../../../Layout";
import {Button, Input} from "../../../Button";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";

const EditReservation = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [subSelectedCategoryId, setSubSelectedCategoryId] = useState();
    const [selectedCounter, setSelectedCounter] = useState(null);
    const [availablePositions, setAvailablePositions] = useState([]);
    const [showInfo, setShowInfo] = useState(false);

    const [caseTime, setCaseTime] = useState();


    useEffect(() => {

        axios.get(`${url}reservation/${localStorage.getItem("reservationId")}?department_id=${localStorage.getItem("selectedDepId")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setName(res.data.reservation.name);
                setPhone(res.data.reservation.phone);
                setTime(res.data.reservation.from.slice(11,16));
                setDate(res.data.reservation.from.slice(0,10));
                setSubSelectedCategoryId(res.data.reservation.category.id)
                setCaseTime(res.data.reservation.category.time);
                console.log(res.data);
            })
            .catch(err => console.log(err));

    }, []);

    const saveReservation = (e) => {
        e.preventDefault();
        const value = {
            "name": name,
            "phone": phone,
            "category_id": subSelectedCategoryId,
            "counter_id": selectedCounter,
            "from": `${date} ${time}`
        };
        console.log(value);

        axios.put(`${url}reservation/${localStorage.getItem("reservationId")}?department_id=${localStorage.getItem("selectedDepId")}`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                toast.success(`Pomyślnie edytowano rezerwację`);
                if(localStorage.getItem("userRole").toString() === "2") {
                    navigate('/panel-employee/calendar/day')
                } else {
                    navigate('/panel-admin/kalendarze/calendar/day')
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error(`Błąd! Uzupełnij wszystkie dane poprawnie`);
            })
    };

    const isMonday = (dateString) => {
        let parseDate = new Date(dateString);
        let dayOfWeek = parseDate.getDay();
        return dayOfWeek === 1;

    }
    const fetchPositions = () => {
        let dateFrom = new Date(`${date} ${time}`);
        function addMinutes(date, minutes) {
            return new Date(date.getTime() + minutes*60000);
        }
        const dateTo = addMinutes(dateFrom, caseTime)
        const getProperMonth = (month) => {
            if(month.toString().length === 1) {
                return `0${month}`;
            }else {
                return month;
            }
        };
        const getProperMinutes = (minutes) => {
            if(minutes.toString().length === 1) {
                return `0${minutes}`;
            }else {
                return minutes;
            }
        };

        const dateToString = `${dateTo.getFullYear()}-${getProperMonth(dateTo.getMonth() + 1)}-${getProperMonth(dateTo.getDate())} ${getProperMonth(dateTo.getHours())}:${getProperMinutes(dateTo.getMinutes())}`
        console.log(dateToString);
        // console.log(to);
        let maxHour = isMonday(dateToString) ? 18 : 16;
        if(parseInt(dateToString.slice(10,13)) >= maxHour && dateToString.slice(14,16) !== "00"){
            toast.error(`Błąd! Koniec sprawy byłby po godz ${maxHour}`);
        } else {
            axios.get(`${url}counter/available?department_id=${localStorage.getItem("selectedDepId")}&from=${date} ${time}&to=${dateToString}`, {
                headers: {
                    Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
                }
            })
                .then(res => {
                    setSelectedCounter(1);
                    setAvailablePositions(res.data.items)
                    setShowInfo(true)
                })
                .catch(err => console.log(err))
        }
    };

    return (
        <>
            <Header title="Kalendarz - edytuj rezerwację"/>
            <MainContent>
                <Form>
                    <div style={{maxWidth: "22rem"}}>
                        <Input
                            type="text"
                            placeholder="Imię i nazwisko"
                            style={{width: "20rem"}}
                            onChange={e => setName(e.target.value)}
                            value={name}
                        />
                        <Input
                            type="text"
                            placeholder="Telefon"
                            style={{width: "20rem"}}
                            onChange={e => setPhone(e.target.value)}
                            value={phone}
                        />
                        <h4>Data</h4>
                        <Input
                            type="date"
                            style={{width: "20rem"}}
                            onChange={e => {
                                setDate(e.target.value);
                                setShowInfo(false);
                                setSelectedCounter(null);
                            }}
                            value={date}
                        />
                        <h4>Godz</h4>
                        <Input
                            type="time"
                            style={{width: "20rem"}}
                            onChange={e => {
                                setTime(e.target.value);
                                setShowInfo(false);
                                setSelectedCounter(null);
                            }}
                            value={time}
                            step={1800}
                        />
                        {showInfo && availablePositions.length &&
                            <div className="select-wrapper">
                                <select onChange={(e) => {
                                    setSelectedCounter(e.target.value);
                                }} name="" id="" >
                                    <option selected disabled>Wybierz stanowisko</option>
                                    {availablePositions.map(d => (
                                        <option key={d.id} value={d.id}>{d.name} {d.is_pos && "POS"}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        {showInfo && !availablePositions.length &&
                            <h4 style={{color: "#CB444A"}}>Brak dostępnych stanowisk w wybranym czasie</h4>
                        }
                        {selectedCounter ?
                            <Button
                                type="button"
                                value="Zapisz"
                                onClick={saveReservation}
                            />
                            :
                            <Button
                                type="button"
                                value="Sprawdź dostępnę stanowiska"
                                onClick={fetchPositions}
                            />
                        }
                    </div>
                </Form>
            </MainContent>
            <ToastContainer/>
        </>
    )
};

export default EditReservation;