import styled from "styled-components";
export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "800px",
        height: "600px",
        backgroundColor: "#fff",
        border: "none",
        position: "relative"
    },
    overlay:{
        backgroundColor: 'rgba(148,148,148,0.63)'
    }
};

export const DeletingConfirmation = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .box{
    height: 300px;
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    h4{
      font-size: 1.6rem;
    }
    .buttons{
      width: 300px;
      display: flex;
      justify-content: space-around;
    ;
      button{
        font-family: "Montserrat", serif;
        border: none;
        color: #ffffff;
        border-radius: 30px;
        padding: 16px 30px;
        cursor: pointer;
        font-size: 13px;
        font-weight: bold;
        width: auto;
      }
    }
  }
`;