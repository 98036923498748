import React, {useEffect, useState, useContext} from "react";
import Header from "../../Header";
import {MainContent} from "../../Layout";
import {Button} from "../../Button/index";
import {Link, NavLink, Outlet} from "react-router-dom";
import * as S from '../style';
import './style.css';
import {fetchDayCalendar, fetchMonthCalendar} from "../../../services/reservations.service";
import {LoadingContext} from "../../../App";
import { usePusher } from "../../../providers/pusher.context";

const Calendary = () => {

    const pusher = usePusher();
    const [loading, setLoading] = useContext(LoadingContext);
    // const [startMonthDate, setStartMonthDate, endMonthDate, setEndMonthDate] = useContext(MonthContext);
    const [refresh, setRefresh] = useState(false);

    const handlePOSSign = () => {
        const x = document.querySelectorAll('.fc-col-header-cell-cushion');

        x.forEach(el => {
            if(el.innerHTML.slice(-1) === ";"){
                el.classList.add("pos");
            }
        });
    };

    handlePOSSign();

    const date = new Date();

    const convertDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const trueMonth = month.toString().length === 1 ? `0${month}`: month;
        const trueDay = day.toString().length === 1 ? `0${day}`: day;

        return `${year}-${trueMonth}-${trueDay}`;
    };

    let firstDay = new Date(date. getFullYear(), date. getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const [departments, setDepartments] = useState(JSON.parse(localStorage.getItem("departments")));
    const [departmentId, setDepartmentId] = useState(JSON.parse(localStorage.getItem("selectedDepId")));
    const [positions, setPositions] = useState([]);
    const [monthEvents, setMonthEvents] = useState([]);
    const [eventDate, setEventDate] = useState(date);
    const [startMonthDate, setStartMonthDate] = useState(convertDate(firstDay));
    const [endMonthDate, setEndMonthDate] = useState(convertDate(lastDay));
    // const [startMonthDate, setStartMonthDate] = useState(localStorage.getItem("firstDay"));
    // const [endMonthDate, setEndMonthDate] = useState(localStorage.getItem("lastDay"));


    useEffect(() => {
        localStorage.setItem("selectedDepId", departmentId.toString());
        handleCalendar(departmentId);
        handleMonthCalendar(departmentId);
    }, [eventDate, startMonthDate, departmentId, refresh]);

    const handleNewReservation = (reservation) => {
        // console.log('refresh');
        setRefresh(!refresh);
    }

    useEffect(() => {
        console.log('connect');
        pusher.subscribe("private-reservations")
        .bind("created", function(data) {
            handleNewReservation(data.reservation);
        } 
    )});

    const handleMonthCalendar = async (depId) => {
        const params = {
            department_id: depId,
            from: startMonthDate,
            to: endMonthDate
        }
            await fetchMonthCalendar(params)
                .then(res => {
                    setMonthEvents(res.days);
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setLoading(true);
                });
    }

    const getProperName = (number) => {
        switch (number) {
            case 1:
                return "Rezerwacja"
            case 2:
                return "Rezerwacje"
            case 3:
                return "Rezerwacje"
            case 4:
                return "Rezerwacje"
            default:
                return "Rezerwacji"
        }
    }

    const convertMonthEvents = (events) => {
        if(events) {
            let reservationsEvents = [];
            let absentsEvents = [];
            events.map(e => {
                if (e.reservations > 0) {
                    reservationsEvents.push({date: e.date, title: `${e.reservations} ${getProperName(e.reservations)}`})
                } else if (e.absents > 0) {
                    absentsEvents.push({date: e.date, title: `Nieobecność`})
                }
            });
            return {reservations: reservationsEvents, absents: absentsEvents};
        }
    }

    const handleCalendar = async (depId) => {
        const params = {
            date: convertDate(eventDate),
            department_id: depId
        };
        await fetchDayCalendar(params)
            .then(res => {
                setPositions(res.counters);
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(true));
    };

    const convertDayEvents = (counters) => {
        if(counters){
            const calendarPositions = counters.map(i => ({
                id: `${i.name} ${i.is_pos ? ";" : ":"}`,
                title: `${i.name} ${i.is_pos ? ";" : ":"}`,
                is_pos: i.is_pos,
                locks: []
            }));
            let calendarEvents = [];
            counters.map(item => {
                let tempObject = {};
                item.items.map(x => {
                    tempObject = {
                        start: x.from,
                        end: x.to,
                        id: x.id,
                        resourceId: `${item.name} ${item.is_pos ? ";" : ":"}`,
                        title: x.label,
                        status: x.status,
                        type: x.type
                    };
                    calendarEvents = [...calendarEvents, tempObject];
                });
            });
            return {events: calendarEvents, positions: calendarPositions};
        }
    }

    const truePositions = convertDayEvents(positions);
    const trueMonth = convertMonthEvents(monthEvents);

    return(
        <>
            <Header title="Kalendarz">
                <div className="select-wrapper">
                    <select onChange={(e) => {
                        setLoading(false);
                        setDepartmentId(e.target.value);
                    }} name="" id="" >
                        {departments && departments.map(d => (
                            <option selected={d.id === departmentId} key={d.id} value={d.id}>{d.name}</option>
                        ))}
                    </select>
                    {/*<Link to="/panel-/dodaj-rezerwacje">*/}
                    {/*    <Button*/}
                    {/*        value="Dodaj nową rezerwację"*/}
                    {/*        style={{marginLeft: " 1rem", backgroundColor: "#CB444A"}}*/}
                    {/*    />*/}
                    {/*</Link>*/}
                </div>
            </Header>
            <MainContent>
                <S.Calendar>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div className="calendar-change-btns">
                            <NavLink onClick={() => {
                                setLoading(false);
                                setRefresh(!refresh);
                            }} to="/panel-admin/kalendarze/calendar/month">
                                <input type="button" value="Miesiąc"/>
                            </NavLink>
                            <NavLink onClick={() => {
                                setLoading(false);
                                setRefresh(!refresh);
                            }} to="/panel-admin/kalendarze/calendar/day">
                                <input type="button" value="Dzień"/>
                            </NavLink>
                        </div>
                        <div className="calendar-nav">
                            <Link to="/panel-admin/kalendarze/usuniete">
                                <Button
                                    type="button"
                                    value="Usunięte"
                                />
                            </Link>
                            <Link to="/panel-admin/kalendarze/nieobecnosci">
                                <Button
                                    type="button"
                                    value="Nieobecności"
                                />
                            </Link>
                        </div>
                    </div>
                    {/*{!active ?*/}
                    {/*    <DayGrid*/}
                    {/*        eventDate={eventDate}*/}
                    {/*        positions={convertDayEvents(positions)}*/}
                    {/*        today={date}*/}
                    {/*        setEventDate={setEventDate}*/}
                    {/*    />*/}
                    {/*    :*/}
                    {/*    <MonthGrid*/}
                    {/*        events={convertMonthEvents(monthEvents)}*/}
                    {/*        active={active}*/}
                    {/*        setActive={setActive}*/}
                    {/*        setEventDate={setEventDate}*/}
                    {/*        setStartMonthDate={setStartMonthDate}*/}
                    {/*        setEndMonthDate={setEndMonthDate}*/}
                    {/*    />*/}
                    {/*}*/}
                    <Outlet context={[eventDate, truePositions, date, setEventDate, trueMonth, startMonthDate, setStartMonthDate, endMonthDate, setEndMonthDate, refresh, setRefresh]}/>
                </S.Calendar>
            </MainContent>
        </>
    )
};

export default Calendary;