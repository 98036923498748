import React, {useEffect, useState} from "react";
import Header from "../../Header";
import {MainContent} from "../../Layout";
import List, {ListTitle, ListItem} from "../../List";
import {ArrowDown, CloseIcon} from "../../../images";
import Modal from 'react-modal';
import axios from "axios";
import {url} from "../../../utils/request";
import "./style.css";
import Pagination from "../../Pagination";
import SearchInput from "../../SearchInput/SearchInput";
import {Button} from "../../Button";
import LoadingComponent from "../../Loading";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {fetchAllTemplates, fetchEmployeeNotif, showEmployeeNotification} from "../../../services/notifications.service";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "800px",
        height: "600px",
        backgroundColor: "#fff",
        border: "none"
    },
    overlay:{
        backgroundColor: 'rgba(148,148,148,0.63)'
    }
};

const EmployeeNotifications = () => {

    const [modalIsOpen, setIsOpen] = useState(false);

    const [selectedDepId, setSelectedDepId] = useState(JSON.parse(localStorage.getItem("selectedDepId")));
    const [departments, setDepartments] = useState(JSON.parse(localStorage.getItem("departments")));

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const [notifications, setNotifications] = useState(null);


    const [notif, setNotif] = useState(null);
    const [schemas, setSchemas] = useState(null);

    const [notifId, setNotifId] = useState();

    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState(false);

    useEffect(() => {
        const params = {
            page: page,
            department_id: selectedDepId,
            search: search
        }
        fetchEmployeeNotif(params)
            .then(res => {
                setLastPage(res.lastPage);
                setNotifications(res.items);
            })
            .catch(err => console.log(err));
    }, [selectedDepId, page, filter]);
    useEffect(() => localStorage.setItem("selectedDepId", selectedDepId.toString()), [selectedDepId]);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        setNotif(null);
    }

    const fetchNotif = async (notif) => {

        const depId = localStorage.getItem("selectedDepId");
        //schemas
        await fetchAllTemplates(depId)
            .then(res => {
                setSchemas(res.items);
            })
            .catch(err => console.log(err))
        //notif
        await showEmployeeNotification(notif, depId)
            .then(res => {
                setNotif(res.notification);
            })
            .catch(err => console.log(err));
    };

    const changeSchema = id => {
        axios.put(`${url}employee/notification/${notifId}?department_id=${selectedDepId}&notification_schema_id=${id}`, {}, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => console.log(res))
            .catch(err => console.log(err));
        fetchNotif(notifId);
    };

    const sendNotif = () => {
        axios.put(`${url}employee/notification/sent/${notifId}?department_id=${selectedDepId}`, {}, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                closeModal();
                toast.success("Pomyślno wysłano powiadomienie");
            })
            .catch(() => {
                toast.error("Błąd! Spróbuj ponownie");
            });
    }

    return(
        <>
            <Header title="Powiadomienia">
                <div className="select-wrapper">
                    <select onChange={(e) => {
                        setSelectedDepId(e.target.value);
                    }} name="" id="" >
                        {departments && departments.map(d => (
                            <option selected={d.id === selectedDepId} key={d.id} value={d.id}>{d.name}</option>
                        ))}
                    </select>
                </div>
            </Header>
            <MainContent>
                <div style={{position: "relative", width: "100%"}}>
                    <SearchInput
                        onClick={(e) => {
                            e.preventDefault();
                            setFilter(!filter);
                        }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            if(!e.target.value.length) setFilter(!filter);
                        }}
                        style={{position: "absolute", right: "3rem", top: "2rem"}}
                    />
                </div>
                <List>
                    <ListTitle>
                        <div style={{width: "20%"}}>
                            <p>Imię i nazwisko</p>
                            <img src={ArrowDown} alt="arrow-down"/>
                        </div>
                        <div style={{width: "20%"}}>
                            <p>Typ sprawy</p>
                            <img src={ArrowDown} alt="arrow-down"/>
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Telefon</p>
                            <img src={ArrowDown} alt="arrow-down"/>

                        </div>
                        <div style={{width: "45%"}}>
                            <p>Email</p>
                            <img src={ArrowDown} alt="arrow-down"/>

                        </div>
                    </ListTitle>
                    {notifications && notifications.map(n => (
                        <ListItem>
                            <div style={{width: "20%"}}>
                                <p>{n.receiver}</p>
                            </div>
                            <div style={{width: "20%", display: "flex", flexDirection: "column"}}>
                                {n.categories.map(c => <p>{c}</p>)}
                            </div>
                            <div style={{width: "15%"}}>
                                <p>{n.phone}</p>
                            </div>
                            <div style={{width: "45%"}}>
                                <p>{n.email}</p>
                                <input onClick={() => {
                                    fetchNotif(n.id);
                                    setNotifId(n.id);
                                    openModal();
                                }} className="notif-send-btn" type="button" value="Wyślij powiadomienie"/>
                            </div>
                        </ListItem>
                    ))}
                </List>
                {!notifications && <LoadingComponent/>}
            </MainContent>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {notif ?
                    <>
                        <div className="notif-modal-title">
                            <h3>Wysyłasz do <br/>{notif.receiver}</h3>
                            <div className="select-wrapper">
                                <select onChange={(e) => changeSchema(e.target.value)} name="" id="" >
                                    {schemas && schemas.map(s => (
                                        <option selected={s.id === notif.notification_schema_id} key={s.id} value={s.id}>{s.title}</option>
                                    ))}
                                </select>
                            </div>
                            <p style={{marginTop: "40px"}}>{notif.title}</p>
                            <p>{notif.content}</p>
                            <Button
                                type="button"
                                value="Potwierdzam treść, WYŚLIJ POWIADOMIENIE"
                                style={{backgroundColor: "#CB444A", textAlign: "center", marginTop: "40px"}}
                                onClick={sendNotif}
                            />
                            <Button
                                type="button"
                                value="ANULUJ"
                                style={{textAlign: "center", marginTop: "2rem"}}
                                onClick={closeModal}
                            />
                        </div>
                        <img onClick={closeModal} className="close-icon" src={CloseIcon} alt="close-icon"/>
                    </>
                    :
                     <LoadingComponent style={{marginTop: "100px"}} />
                }
            </Modal>
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
            <ToastContainer/>
        </>
    )
};



export default EmployeeNotifications;