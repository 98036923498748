import request from "../utils/request";
const fetchUsers = async (page, role) => {
    try {
        const { data, status } = await request({
            url: `user?page=${page}&role_id=${role}`,
            method: "GET",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};
const deleteUser = async (id) => {
    try {
        const { data, status } = await request({
            url: `user/${id}`,
            method: "DELETE",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

export {
    fetchUsers,
    deleteUser
};