import React, {useState, useEffect} from "react";
import {Button} from "../../Button/index";
import Header from "../../Header/index";
import {MainContent} from "../../Layout/index";
import List, {ListTitle, ListItem} from "../../List/index";
import {ArrowDown, EditIcon, TrashIcon} from "../../../images/index";
import Pagination from "../../Pagination/index";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import {customStyles} from "../../modalStyles";
import * as S from "../../modalStyles"
import Modal from "react-modal";
import LoadingComponent from "../../Loading";
import {fetchCases, deleteCase} from "../../../services/cases.service";


const Cases = () => {
    const navigate = useNavigate();

    const [cases, setCases] = useState(null);
    const [lastPage, setLastPage] = useState(1);
    const [page, setPage] = useState(1);
    const [refresh, setRefresh] = useState(false);

    const [confirmationName, setConfirmationName] = useState('');
    const [confirmationId, setConfirmationId] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    function closeModal() {
        setIsOpen(false);
    }
    function openModal() {
        setIsOpen(true);
    }

    useEffect(() => {
        fetchCases(page)
            .then(res => {
                setLastPage(res.lastPage);
                setCases(res.items);
            })
            .catch(err => console.log(err));
    }, [page, refresh]);

    const handleDelete = async (id1, name) => {
        await deleteCase(id1)
            .then(() => {
                toast.success(`Pomyślnie usunięto ${name}`);
                setRefresh(!refresh);
            })
            .catch(err => {
                toast.error(`Błąd! Spróbuj ponownie`);
            })
    };

    return(
        <>
            <Header title="Sprawy">
                <Button
                    value="Dodaj sprawę"
                    type="button"
                    style={{backgroundColor: "#CB444A", color: "#FFFFFF"}}
                    onClick={() => navigate("/panel-admin/sprawy/dodaj")}
                />
            </Header>
            <MainContent>
                <List>
                    <ListTitle>
                        <div style={{width: "35%"}}>
                            <p>Kategoria główna</p>
                            <img
                                src={ArrowDown}
                                alt="arror-down"
                            />
                        </div>
                        <div style={{width: "20%"}}>
                            <p>Liczba podkategorii</p>
                            <img
                                src={ArrowDown}
                                alt="arror-down"
                            />
                        </div>
                        <div style={{width: "30%"}}>
                            <p>Wydział</p>
                            <img
                                src={ArrowDown}
                                alt="arror-down"
                            />
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Akcja</p>
                        </div>
                    </ListTitle>
                    {cases && cases.map(c =>
                        <ListItem key={c.id}>
                            <div style={{width: "35%"}}>
                                <p>{c.name}</p>
                            </div>
                            <div style={{width: "20%"}}>
                                <p>{c.children_count}</p>
                                {/*<div className="box_children">{c.children.map(c => <p key={c.id}>{c.name}</p>)}</div>*/}
                            </div>
                            <div style={{width: "30%"}}>
                                <p>{c.department_name}</p>
                            </div>
                            <div className="list_action_container" style={{width: "15%"}}>
                                {/*<img*/}
                                {/*    src={AngleIcon}*/}
                                {/*    alt="angle-icon"*/}
                                {/*    style={{width: ".8rem", marginRight: "4px",marginTop: "16px", rotate: "180deg"}}*/}
                                {/*/>*/}
                                <img
                                    src={EditIcon}
                                    alt="edit-icon"
                                    onClick={() => {
                                        localStorage.setItem("editedCaseId", c.id)
                                        navigate("/panel-admin/sprawy/edytuj")
                                    }}
                                />
                                <img
                                    src={TrashIcon}
                                    alt="trash-icon"
                                    onClick={() => {
                                        setConfirmationName(c.name);
                                        setConfirmationId(c.id);
                                        openModal();
                                    }}
                                />
                            </div>
                        </ListItem>)}
                </List>
                {!cases && <LoadingComponent/>}
            </MainContent>
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
            <ToastContainer/>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <S.DeletingConfirmation>
                    <div className="box">
                        <h4>Czy na pewno usunąć {confirmationName}?</h4>
                        <div className="buttons">
                            <button style={{backgroundColor: "#CB444A"}} onClick={() => {
                                closeModal();
                                handleDelete(confirmationId, confirmationName);
                            }}>Tak</button>
                            <button style={{backgroundColor: "#181818"}}  onClick={() => closeModal()}>Nie</button>
                        </div>
                    </div>
                </S.DeletingConfirmation>
            </Modal>
        </>
    )
}

export default Cases;