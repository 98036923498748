import Navigation from "../Navigation/Navigation";
import * as S from "./style";

export const Form = ({children, onSubmit, style}) => {
    return(
        <S.Form onSubmit={onSubmit} style={style}>
            {children}
        </S.Form>
    )
}
export const MainContent = ({children, style}) => {
    return(
        <S.MainContent style={style}>
            {children}
        </S.MainContent>
    )
}
export const HalfMainContent = ({children, style}) => {
    return(
        <S.HalfMainContent style={style}>
            {children}
        </S.HalfMainContent>
    )
}

const Main = ({children}) => {
    return(
        <S.Main>
            {children}
        </S.Main>
    )
}

const Layout = ({children}) => {
    return(
        <S.Layout>
            <Navigation />
            <Main>{children}</Main>
        </S.Layout>
)};

export default Layout;