import React, {useState, useEffect} from "react";
import axios from "axios";
import {Button, Input} from "../../../Button/index";
import Header from "../../../Header/index";
import {MainContent, Form} from "../../../Layout/index";
import {url} from "../../../../utils/request";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import List, {ListItem, ListTitle} from "../../../List/index";
import {TrashIcon} from "../../../../images/index";
import {useNavigate} from "react-router-dom";

const AddNewDepartmentComponent = ({onChangeName, nameValue, isPosValue, onChangePos}) => {
    return(
        <div style={{display: "block"}}>
            <Input
                type="text"
                placeholder="Nazwa stanowiska"
                onChange={onChangeName}
                value={nameValue}
                style={{width: "20rem", marginBottom: ".5rem"}}
            />
            <input style={{marginLeft: "11px"}} type="checkbox" onChange={onChangePos} checked={isPosValue}/>
            <label>To stanowisko jest POSem</label>
        </div>
    )
}

const EditDepartment = () => {
    const navigate = useNavigate();

    const [department, setDepartment] = useState(null);
    const [departmentName, setDepartmentName] = useState("");
    const [positions, setPositions] = useState(null);
    const [newPositions, setNewPositions] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const newPosition = {name: "", is_pos: 0};

    useEffect(() => {
        axios.get(`https://api-ewizyty.wsparcie-jarjarocin.pl/api/V1/panel/department/${localStorage.getItem("editedDepartmentId")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setDepartment(res.data.department)
                setPositions(res.data.department.counters)
                setDepartmentName(res.data.department.name);
                console.log(res)
            })
            .catch(err => console.log(err))
    }, [refresh])

    const changeName = (index, e) => {
        let newArr = [...positions];
        newArr[index].name = e.target.value;
        setPositions(newArr);
    }
    const changePos = index => {
        let newArr = [...positions];
        newArr[index].is_pos = newArr[index].is_pos === 1 ? 0 : 1;
        setPositions(newArr);
    }
    const changeNewName = (index, e) => {
        let newArr = [...newPositions];
        newArr[index].name = e.target.value;
        setNewPositions(newArr);
    }
    const changeNewPos = index => {
        let newArr = [...newPositions];
        newArr[index].is_pos = newArr[index].is_pos === 1 ? 0 : 1;
        setNewPositions(newArr);
    }

    const updateDepartment = (e) => {
        e.preventDefault();

        const positionsApi = positions.map((p, index) => `&counters[${index}][id]=${p.id}&counters[${index}][name]=${p.name}&counters[${index}][is_pos]=${p.is_pos}`);
        const lastIndex = positions.length;
        const newPositionsApi = newPositions.map((p, index) => `&counters[${lastIndex + index}][name]=${p.name}&counters[${lastIndex + index}][is_pos]=${p.is_pos}`);

        const finalApi = positionsApi.concat(newPositionsApi);
        let value = '';
        const doThis = () => {
            finalApi.map(elm => value = value + elm);
        }
        doThis();
        // let doThis = finalApi.map(elm => value = value + elm);

        const update = `${url}department/${localStorage.getItem("editedDepartmentId")}?name=${departmentName}${value}`;

        axios.put(update, {},  {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success("Pomyślnie edytowano wydział");
                navigate('/panel-admin/wydzialy');
            })
            .catch(() => {
                toast.error("Błąd! Spróbuj ponownie");
            })
    }

    const deletePosition = id => {
        axios.delete(`${url}counter/${id}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success("Pomyślnie usunięto stanowisko");
                setRefresh(!refresh)
            })
            .catch(() => toast.error("Błąd! Spróbuj ponownie"));
    }



    return(
        <>
            <Header title="Edytuj Wydział" />
            <MainContent>
                {department &&
                    <Form onSubmit={updateDepartment}>
                        <h4>Edytuj nazwę wydziału</h4>
                        <Input
                            type="text"
                            onChange={e => setDepartmentName(e.target.value)}
                            value={departmentName}
                            style={{width: "20rem"}}
                        />
                        <h4>Edytuj stanowiska</h4>
                        {positions.map((p, index) => (
                            <AddNewDepartmentComponent
                                key={index}
                                nameValue={p.name}
                                isPosValue={p.is_pos}
                                onChangeName={(e) => changeName(index, e)}
                                onChangePos={() => changePos(index)}
                                id={p.id}
                            />
                        ))}
                        {newPositions.map((p, index) => (
                            <AddNewDepartmentComponent
                                key={index}
                                nameValue={p.name}
                                isPosValue={p.is_pos}
                                onChangeName={(e) => changeNewName(index, e)}
                                onChangePos={() => changeNewPos(index)}
                                newPositions={newPositions}
                            />
                        ))}
                        <Button
                            type="button"
                            value="Dodaj kolejne stanowisko"
                            style={{display: "block", margin: ".75rem"}}
                            onClick={() => setNewPositions([...newPositions, newPosition])}
                        />
                        <Button
                            type="submit"
                            value="Zapisz"
                        />
                    </Form>
                }
                {department && <h4 style={{margin: "1.5rem 0 -3rem 2rem"}}>Stanowiska {department.name}</h4>}
                <List>
                    <ListTitle>
                        <div style={{width: "55%"}}>
                            <p>Nazwa stanowiska</p>
                        </div>
                        <div style={{width: "30%"}}>
                            <p>POS</p>
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Akcja</p>
                        </div>
                    </ListTitle>
                    {positions && positions.map( p =>
                        <ListItem key={p.id}>
                            <div style={{width: "55%"}}>
                                <p>{p.name}</p>
                            </div>
                            <div style={{width: "30%"}}>
                                <p>{p.is_pos === 1 ? "Tak" : "Nie"}</p>
                            </div>
                            <div className="list_action_container" style={{width: "15%"}}>
                                <img
                                    src={TrashIcon}
                                    alt="trash-icon"
                                    style={{marginLeft: ".5rem"}}
                                    onClick={() => deletePosition(p.id)}
                                />
                            </div>
                        </ListItem>
                    )}
                </List>
            </MainContent>
            <ToastContainer/>
        </>
    )
};

export default EditDepartment;