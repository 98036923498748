import styled from "styled-components";

export const CasesContainer = styled.div`
    max-width: 45rem;
      .deleted{
        opacity: .3;
      }
    .select-wrapper {
      position: relative;
      font-family: "Montserrat", serif;
      select {
        font-family: "Montserrat", serif;
        border: none;
        border-radius: 30px;
        padding: 16px 30px;
        background-color: #F0F0F3;
        font-size: 13px;
        outline: none;
        margin-bottom: .6rem;
        width: 20rem;
        border-right: 2rem solid transparent;
      }
    }
  .selected-case{
    font-family: "Montserrat", serif;
    border: none;
    border-radius: 30px;
    padding: 16px 30px;
    background-color: #ddd;
    font-size: 13px;
    outline: none;
    margin-bottom: .6rem;
    p{
      color: #000000;
      font-size: 13px;
      }
    }
  div{
    .add_button{
      margin-left: 1rem;
      border-radius: 100%;
      width: 48px;
      height: 48px;
      font-size: 22px;
      cursor: pointer;
      font-weight: bold;
      border: none;
      outline: none;
      color: #FFF;
      background-color: #363636;
    }
    img{
      cursor: pointer;
      width: 1.5rem; 
      height: auto;
      position: relative;
      top: .5rem;
      left: 1.7rem;
    }
  }
  
`;