import React from "react";
import {Outlet} from "react-router-dom";
import Layout from "../Layout";

const PanelEmployee = () => {
    return(
        <>
            <Layout>
                <Outlet/>
            </Layout>
        </>
    )
};

export default PanelEmployee;