import React, {useState, useEffect} from "react";
import axios from "axios";
import Header from "../../../Header/index";
import {Form, MainContent} from "../../../Layout/index";
import {Button, Input} from "../../../Button/index";
import List, {ListItem, ListTitle} from "../../../List/index";
import {ArrowDown} from "../../../../images/index";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {url} from "../../../../utils/request";
import {useNavigate} from "react-router-dom";

const EditEmployee = () => {
    const navigate = useNavigate();

    const [departments, setDepartments] = useState(null);
    const [finalDep, setFinalDep] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    useEffect(() => {
        axios.get(`${url}user/${localStorage.getItem("editedEmployee")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setFinalDep(res.data.user.departments.map(d => d.id));
                setName(res.data.user.name);
                setEmail(res.data.user.email);
                setPhone(res.data.user.phone);
            })
            .catch(err => console.log(err));
        axios.get(`${url}department/all`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setDepartments(res.data.items);
            })
            .catch(err => console.log(err))
    }, [])

    const editEmployee = (e) => {
        e.preventDefault();
        const value = {
            "name": name,
            "role_id": 2,
            "phone": phone,
            "email": email,
            "departments": finalDep,
            "password": password,
            "password_confirmation": passwordConfirmation
        }

        axios.put(`${url}user/${localStorage.getItem("editedEmployee")}` , value , {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success("Pomyślnie edytowano pracownika");
                navigate('/panel-admin/pracownicy');
            })
            .catch(() => toast.error("Błąd! Spróbuj ponownie"))
    };

    const editDepartments = id => {
        if(finalDep.find(el => el === id)){
            setFinalDep(finalDep.filter(el => el !== id));
        }else{
            setFinalDep([...finalDep, id]);
        }
    }

    return(
        <>
            <Header title="Edytuj pracownika" />
            <MainContent>
                <Form onSubmit={editEmployee}>
                    <div style={{maxWidth: "20rem"}}>
                        <h4>Imię i nazwisko</h4>
                        <Input
                            type="text"
                            placeholder="Imię i nazwisko"
                            style={{width: "20rem"}}
                            onChange={e => setName(e.target.value)}
                            value={name}
                        />
                        <h4>Adres email</h4>
                        <Input
                            type="text"
                            placeholder="E-mail"
                            style={{width: "20rem"}}
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                        <h4>Nr telefonu</h4>
                        <Input
                            type="text"
                            placeholder="Telefon"
                            style={{width: "20rem"}}
                            onChange={e => setPhone(e.target.value)}
                            value={phone}
                        />
                        <h4>Nadaj nowe hasło</h4>
                        <Input
                            type="password"
                            placeholder="Nowe hasło"
                            style={{width: "20rem"}}
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                        />
                        <Input
                            type="password"
                            placeholder="Potwierdz nowe hasło"
                            style={{width: "20rem"}}
                            onChange={e => setPasswordConfirmation(e.target.value)}
                            value={passwordConfirmation}
                        />
                    </div>
                    <h4>Edytuj przydział do wydziałów</h4>
                    <List style={{margin: "-4rem 0 2.5rem 0", width: "100%"}}>
                        <ListTitle>
                            <div style={{width: "5%"}}>
                                <p>Dodaj</p>
                            </div>
                            <div style={{width: "95%"}}>
                                <p>Nazwa</p>
                                <img
                                    src={ArrowDown}
                                    alt="arrow-down"
                                />
                            </div>
                        </ListTitle>
                        {departments && departments.map( d =>
                            <ListItem key={d.id}>
                                <div style={{width: "5%"}}>
                                    <input type="checkbox" onChange={() => editDepartments(d.id)} checked={finalDep.includes(d.id)}/>
                                </div>
                                <div style={{width: "95%"}}>
                                    <p>{d.name}</p>
                                </div>
                            </ListItem>
                        )}
                    </List>
                    <Button
                        type="submit"
                        value="Zapisz"
                    />
                </Form>
            </MainContent>
            <ToastContainer/>
        </>
    )
};

export default EditEmployee;