import React, {useState, useEffect} from "react";
import Header from "../../../Header";
import {MainContent} from "../../../Layout";
import axios from "axios";
import {url} from "../../../../utils/request";
import List, {ListTitle, ListItem} from "../../../List";
import {ArrowDown, SearchIcon} from "../../../../images";
import * as S from '../../style';
import Pagination from "../../../Pagination";
import LoadingComponent from "../../../Loading";

const CalendaryDeleted = () => {
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const [departments, setDepartments] = useState(JSON.parse(localStorage.getItem("departments")));
    const [selectedDepId, setSelectedDepId] = useState(JSON.parse(localStorage.getItem("selectedDepId")));
    const [search, setSearch] = useState('');
    const [deletedItems, setDeletedItems] = useState(null);

    useEffect(() => {
        fetchDeletedData(selectedDepId);
    }, [selectedDepId, page]);
    useEffect(() => localStorage.setItem("selectedDepId", selectedDepId.toString()), [selectedDepId]);

    const fetchDeletedData = id1 => {
        axios.get(`${url}reservation/deleted?department_id=${id1}&search=${search}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setLastPage(res.data.lastPage);
                setDeletedItems(res.data.items);
            })
            .catch(err => console.log(err))
    }

    return(
        <>
            <Header title="Usunięte">
                <div className="select-wrapper">
                    <select onChange={(e) => {
                        setSelectedDepId(e.target.value);
                    }} name="" id="" >
                        {departments && departments.map(d => (
                            <option selected={d.id === selectedDepId} key={d.id} value={d.id}>{d.name}</option>
                        ))}
                    </select>
                </div>
            </Header>
            <MainContent>
                    <S.DeletedCalendar>
                        <div className="search-input">
                            <input type="search" placeholder="Szukaj" onChange={e => setSearch(e.target.value)}/>
                            <img src={SearchIcon} alt="search-icon" onClick={() => fetchDeletedData(selectedDepId)}/>
                        </div>
                        <List>
                            <ListTitle>
                                <div style={{width: "20%"}}>
                                    <p>Godzina data wizity</p>
                                    <img src={ArrowDown} alt="arrow-down"/>
                                </div>
                                <div style={{width: "20%"}}>
                                    <p>Imię i nazwisko</p>
                                    <img src={ArrowDown} alt="arrow-down"/>
                                </div>
                                <div style={{width: "20%"}}>
                                    <p>Stanowisko</p>
                                    <img src={ArrowDown} alt="arrow-down"/>
                                </div>
                                <div style={{width: "25%"}}>
                                    <p>Usunięte przez (ID - IMIĘ)</p>
                                    <img src={ArrowDown} alt="arrow-down"/>
                                </div>
                                <div style={{width: "15%"}}>
                                    <p>Telefon</p>
                                    <img src={ArrowDown} alt="arrow-down"/>
                                </div>
                            </ListTitle>
                            {deletedItems && deletedItems.map(i => (
                                    <ListItem>
                                        <div style={{width: "20%"}}>
                                            <p>{i.from.slice(0,10)} {i.from.slice(11,16)}</p>
                                        </div>
                                        <div style={{width: "20%"}}>
                                            <p>{i.name}</p>
                                        </div>
                                        <div style={{width: "20%"}}>
                                            <p>{i.counter}</p>
                                        </div>
                                        <div style={{width: "25%"}}>
                                            <p>{i.deleted_by_user_id} - {i.deleted_by_user_name}</p>
                                        </div>
                                        <div style={{width: "15%"}}>
                                            <p>{i.phone}</p>
                                        </div>
                                    </ListItem>
                                ))}
                        </List>
                        {!deletedItems && <LoadingComponent />}
                        <Pagination style={{position: "absolute", top: "87%", margin: "0", width: "100%"}} page={page} setPage={setPage} lastPage={lastPage} />
                    </S.DeletedCalendar>
            </MainContent>
        </>
    )
};

export default CalendaryDeleted;