import React, {useState, useEffect} from "react";
import axios from "axios";
import {url} from "../../../utils/request";
import Header from "../../Header";
import {HalfMainContent, Form} from "../../Layout";
import * as S from "../style";
import {ArrowDown, EditIcon, TrashIcon} from "../../../images";
import List, {ListTitle, ListItem} from "../../List";
import {Button, Input} from "../../Button";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "../../Pagination";
import {useNavigate} from "react-router-dom";
import {customStyles} from "../../modalStyles";
import * as Q from "../../modalStyles"
import Modal from "react-modal";
import LoadingComponent from "../../Loading";
import {fetchAllCases} from "../../../services/cases.service";
import {fetchTemplates, deleteTemplate} from "../../../services/notifications.service";

const SystemNotificatonTemplate = () => {
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const [schemas, setSchemas] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCatId, setSelectedCatId] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [sms, setSms] = useState(false);
    const [email, setEmail] = useState(true);

    const [reload, setReload] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [confirmationName, setConfirmationName] = useState('');
    const [confirmationId, setConfirmationId] = useState('');

    function closeModal() {
        setIsOpen(false);
    }
    function openModal() {
        setIsOpen(true);
    }

    useEffect(() => {
        fetchTemplates(page, 'system')
            .then(res => {
                setSchemas(res.items);
                setLastPage(res.lastPage);
            })
            .catch(err => console.log(err));

        fetchAllCases()
            .then(res => {
            setCategories(res.items);
        })
            .catch(err => console.log(err));
    }, [page, reload])

    const addCatId = id => {
        if(selectedCatId.includes(parseInt(id))){
            toast.error("Ta sprawa już została dodana");
        }else{
            setSelectedCatId([...selectedCatId, parseInt(id)]);
            const value = categories.filter(el => el.id === parseInt(id));
            setSelectedCategories([...selectedCategories, value[0]]);
        }
    }
    const deleteCat = id => {
        setSelectedCategories(selectedCategories.filter(el => el.id !== id));
        setSelectedCatId(selectedCatId.filter(el => el !== id));
    }

    const deleteSchema = async (id1, name) => {
        await deleteTemplate(id1)
            .then(() => {
                setReload(!reload);
                toast.success(`Pomyślnie usunięto ${name}`);
            })
            .catch(() => {
                toast.error("Błąd! Spróbuj ponownie")
            });
    }

    const saveSchema = (e) => {
        e.preventDefault();
        const value = {
            "title": title,
            "content": content,
            "sms": sms,
            "email": email,
            "categories": selectedCatId,
            "type": "system"
        }
        axios.post(`${url}notificationSchema`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                setReload(!reload);
                setEmail(false);
                setSms(false);
                setTitle('');
                setContent('');
                setSelectedCatId([]);
                setSelectedCategories([]);
                toast.success("Pomyślnie dodano szablon");
            })
            .catch(() => toast.error("Uzupełnij wszystkie pola!"));
    }

    const handleSetMail = () =>  {
        setEmail(true);
        setSms(false);
    }

    const handleSetSms = () => {
        setSms(true);
        setEmail(false);
    }

    return(
        <>
            <Header title="Powiadomienia systemowe"/>
            <S.NotifTemplate>
                <HalfMainContent style={{width: "calc(45% - .5rem)"}}>
                    <List style={{width: "calc(100% - 2rem)", top: "2rem"}}>
                        <ListTitle>
                            <div style={{width: "45%"}}>
                                <p>Tytuł</p>
                                <img src={ArrowDown} alt="arrow-down"/>
                            </div>
                            <div style={{width: "45%"}}>
                                <p>Kategoria</p>
                                <img src={ArrowDown} alt="arrow-down"/>
                            </div>
                            <div style={{width: "10%"}}>
                                <p>Akcja</p>
                            </div>
                        </ListTitle>
                        {schemas && schemas.map(s =>
                            <ListItem>
                                <div style={{width: "45%"}}>
                                    <p>{s.title}</p>
                                </div>
                                <div style={{width: "45%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent:"center"}}>
                                    {s.categories.map(c => <p key={c.id}>{c.name}</p>)}
                                </div>
                                <div className="list_action_container" style={{width: "10%"}}>
                                    <img onClick={() => {
                                        navigate("/panel-admin/powiadomienia-systemowe/edytuj");
                                        localStorage.setItem("editedSchema", s.id);
                                    }} src={EditIcon} alt="edit-icon"/>
                                    <img onClick={() => {
                                        setConfirmationName(s.title);
                                        setConfirmationId(s.id);
                                        openModal();
                                    }} src={TrashIcon} alt="trash-icon"/>
                                </div>
                            </ListItem>
                        )}
                    </List>
                    {!schemas && <LoadingComponent style={{marginBottom: "100px"}}/>}
                    <Pagination page={page} setPage={setPage} lastPage={lastPage} />
                </HalfMainContent>
                <HalfMainContent style={{marginLeft: "1rem", width: "calc(55% - .5rem)"}}>
                    <Form onSubmit={saveSchema} style={{top: "2rem"}}>
                        <div style={{maxWidth: "20rem"}}>
                            <input type="checkbox" checked={email} onChange={() => handleSetMail()}/> <span>E-mail</span>
                            <input style={{marginLeft: "1rem"}} type="checkbox" checked={sms} onChange={() => handleSetSms()}/> <span>SMS</span>
                        </div>
                            <List style={{top: "1rem", marginBottom: "3rem", width: "100%"}}>
                                <ListTitle>
                                    <div style={{width: "90%"}}>
                                        <p>Wybrane sprawy</p>
                                        <img src={ArrowDown} alt="arrow-down"/>
                                    </div>
                                    <div style={{width: "10%"}}>
                                        <p>Akcja</p>
                                    </div>
                                </ListTitle>
                                {selectedCategories.map(s =>
                                    <ListItem key={s.id}>
                                        <div style={{width: "90%"}}>
                                            <p>{s.name}</p>
                                        </div>
                                        <div className="list_action_container" style={{width: "10%"}}>
                                            <img onClick={() => {
                                                deleteCat(s.id)
                                            }} src={TrashIcon} alt="trash-icon"/>
                                        </div>
                                    </ListItem>
                                )}
                            </List>
                            <div className="select-wrapper-notify">
                                <select onChange={(e) => addCatId(e.target.value)} >
                                    <option disabled selected value="">Przydziel do spraw</option>
                                    {categories && categories.map(c => (
                                            <option key={c.id} value={c.id}>{c.name}</option>
                                    ))}
                                </select>
                            </div>
                            <Input
                                type="text"
                                placeholder="Tytuł"
                                style={{width: "20rem"}}
                                onChange={e => setTitle(e.target.value)}
                                value={title}
                            />
                        <textarea
                            placeholder="Treść"
                            cols="30"
                            rows="10"
                            value={content}
                            onChange={e => setContent(e.target.value)}
                            maxLength={
                                sms ? 160 : 4367
                            }
                        />
                        <p style={{
                                fontSize: ".75rem",
                                fontWeight: 600,
                                color: "#808080",
                        }}>Pozostało znaków: {sms ? 160 - content.length : 4367 - content.length}</p>
                        <p style={{
                            fontSize: ".75rem",
                            fontWeight: 600,
                            color: "#808080",
                        }}>Dostępne znaczniki: <b>[date]</b>, <b>[caseName]</b>, <b>[name]</b></p>
                        <Button
                            type="submit"
                            value="Zapisz"
                        />
                    </Form>
                </HalfMainContent>
            </S.NotifTemplate>
            <ToastContainer/>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <Q.DeletingConfirmation>
                    <div className="box">
                        <h4>Czy na pewno usunąć {confirmationName}?</h4>
                        <div className="buttons">
                            <button style={{backgroundColor: "#CB444A"}} onClick={() => {
                                closeModal();
                                deleteSchema(confirmationId, confirmationName);
                            }}>Tak</button>
                            <button style={{backgroundColor: "#181818"}}  onClick={() => closeModal()}>Nie</button>
                        </div>
                    </div>
                </Q.DeletingConfirmation>
            </Modal>
        </>
    )
};

export default SystemNotificatonTemplate;