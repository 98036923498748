import React, {useState} from 'react';
import axios from 'axios';
import {Button, Input} from "../Button/index";
import * as S from './style';
import Logo from '../../images/logo_jarocin 1.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {url} from "../../utils/request";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    localStorage.clear();
    window.sessionStorage.clear();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const userLoginData = {
        "email": email,
        "password": password
    };

    const setDepartments = () => {
        axios.get(`${url}department/all`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                let deps = res.data.items;
                localStorage.setItem("departments", JSON.stringify(deps));
                localStorage.setItem("selectedDepId", res.data.items[0].id)
            })
            .catch(err => console.log(err))
    }

    const setCalendarDate = () => {
        const date = new Date();
        const convertDate = (date) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            const trueMonth = month.toString().length === 1 ? `0${month}`: month;
            const trueDay = day.toString().length === 1 ? `0${day}`: day;

            return `${year}-${trueMonth}-${trueDay}`;
        };

        let firstDay = new Date(date. getFullYear(), date. getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        localStorage.setItem("firstDay", convertDate(firstDay));
        localStorage.setItem("lastDay", convertDate(lastDay));
    }

    const postLogin = (e) => {
        e.preventDefault();

        if(email !== "" && password !== ""){
            axios.post(`${url}auth/login`, userLoginData)
                .then(response => {
                    // setCalendarDate();
                    let deps = response.data.user.departments;
                    toast.success("Pomyślnie zalogowano");
                    window.sessionStorage.setItem('accessToken', response.data.access_token);
                    window.sessionStorage.setItem('loggedAs', response.data.user.roles[0].name);
                    localStorage.setItem('userRole', response.data.user.roles[0].id);
                    localStorage.setItem('userName', response.data.user.name);
                    localStorage.setItem('userId', response.data.user.id);
                    localStorage.setItem('userEmail', response.data.user.email);
                    localStorage.setItem('userPhone', response.data.user.phone);
                    if(!deps.length) {
                        setDepartments();
                    } else {
                        localStorage.setItem("departments", JSON.stringify(deps));
                        localStorage.setItem("selectedDepId", deps[0].id)
                    }

                    navigate(response.data.user.roles[0].id === 1 ? "/panel-admin" : "/panel-employee/calendar/day");
                })
                .catch((error) => {
                    console.log(error)
                    toast.error("Błędne dane!");
                    setEmail("")
                    setPassword("")
                });
        }else{
            toast.error("Uzupełnij dane!");
        }
    };

    return(
        <S.LoginContainer>
            <div className="login_poster">
                <img src={Logo} alt="Logo Jarocina"/>
            </div>
            <form onSubmit={postLogin}>
                <h5>Logowanie</h5>
                <Input
                    type="text"
                    placeholder="Adres e-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Hasło"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <Button
                    type="submit"
                    value="Zaloguj się"
                />
            </form>
            <ToastContainer/>
        </S.LoginContainer>
    )
};

export default Login;