import React, {useEffect, useState} from "react";
import Header from "../../Header/index";
import {Button} from "../../Button/index";
import {MainContent} from "../../Layout";
import List, {ListItem, ListTitle} from "../../List/index";
import {ArrowDown, TrashIcon, EditIcon} from "../../../images/index";
import Pagination from "../../Pagination/index";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import {customStyles} from "../../modalStyles";
import * as S from "../../modalStyles"
import Modal from "react-modal";
import LoadingComponent from "../../Loading";
import {fetchUsers, deleteUser} from "../../../services/users.service";

const Admins = () => {
    const navigate = useNavigate();

    const [confirmationName, setConfirmationName] = useState('');
    const [confirmationId, setConfirmationId] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    function closeModal() {
        setIsOpen(false);
    }
    function openModal() {
        setIsOpen(true);
    }

    const [admins, setAdmins] = useState(null);
    const [lastPage, setLastPage] = useState(1);
    const [page, setPage] = useState(1);

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetchUsers(page, 1)
            .then(res => {
                setLastPage(res.lastPage);
                setAdmins(res.items);
            })
            .catch(err => console.log(err));
    }, [page, refresh]);


    const handleDelete = async (id1, name) => {
        await deleteUser(id1)
            .then(() => {
                toast.success(`Pomyślnie usunięto ${name}`);
                setRefresh(!refresh);
            })
            .catch(() => {
                toast.error(`Błąd! Spróbuj ponownie`);
            })
    };

    return(
        <>
            <Header title="Administratorzy">
                <Button
                    value="Dodaj administratora"
                    type="button"
                    style={{backgroundColor: "#CB444A", color: "#FFFFFF"}}
                    onClick={() => navigate("/panel-admin/administratorzy/dodaj")}
                />
            </Header>
            <MainContent>
                <List>
                    <ListTitle>
                        <div style={{width: "5%"}}>
                            <p>Id</p>
                            <img src={ArrowDown} alt="arror-down"/>
                        </div>
                        <div style={{width: "30%"}}>
                            <p>Imię i nazwisko</p>
                            <img src={ArrowDown} alt="arror-down"/>

                        </div>
                        <div style={{width: "25%"}}>
                            <p>Numer telefonu</p>
                            <img src={ArrowDown} alt="arror-down"/>

                        </div>
                        <div style={{width: "25%"}}>
                            <p>E-mail</p>
                            <img src={ArrowDown} alt="arror-down"/>

                        </div>
                        <div style={{width: "15%"}}>
                            <p>Akcja</p>
                        </div>
                    </ListTitle>
                    {admins && admins.map(a =>
                        <ListItem key={a.id}>
                            <div style={{width: "5%"}}>
                                <p>{a.id}</p>
                            </div>
                            <div style={{width: "30%"}}>
                                <p>{a.name}</p>
                            </div>
                            <div style={{width: "25%"}}>
                                <p>{a.phone}</p>
                            </div>
                            <div style={{width: "25%"}}>
                                <p>{a.email}</p>
                            </div>
                            <div className="list_action_container" style={{width: "15%"}}>
                                {a.id.toString() !== localStorage.getItem('userId') &&
                                    <>
                                        <img src={EditIcon}
                                             alt="edit-icon"
                                             style={{marginLeft: ".5rem"}}
                                             onClick={() => {
                                                  localStorage.setItem("editedAdmin", a.id);
                                                  navigate("/panel-admin/administratorzy/edytuj");
                                             }}
                                        />
                                        <img src={TrashIcon}
                                             alt="trash-icon"
                                             style={{marginLeft: ".5rem"}}
                                             onClick={() => {
                                                 setConfirmationName(a.name);
                                                 setConfirmationId(a.id);
                                                 openModal();
                                             }}
                                        />
                                    </>
                                }
                            </div>
                        </ListItem>
                    )}
                </List>
                {!admins && <LoadingComponent/>}
            </MainContent>
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
            <ToastContainer/>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <S.DeletingConfirmation>
                    <div className="box">
                        <h4>Czy na pewno usunąć {confirmationName}?</h4>
                        <div className="buttons">
                            <button style={{backgroundColor: "#CB444A"}} onClick={() => {
                                closeModal();
                                handleDelete(confirmationId, confirmationName);
                            }}>Tak</button>
                            <button style={{backgroundColor: "#181818"}}  onClick={() => closeModal()}>Nie</button>
                        </div>
                    </div>
                </S.DeletingConfirmation>
            </Modal>
        </>
    )
};

export default Admins;