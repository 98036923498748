import request from "../utils/request";

const fetchDeleted = async (params) => {
    try {
        const { data, status } = await request({
            url: `reservation/deleted`,
            method: "GET",
            params: params
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const fetchMonthCalendar = async (params) => {
    try {
        const { data, status } = await request({
            url: `calendar/month`,
            method: "GET",
            params: params
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const fetchDayCalendar = async (params) => {
    try {
        const { data, status } = await request({
            url: `calendar`,
            method: "GET",
            params: params
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

const fetchReservationsHistory = async (params) => {
    try {
        const { data, status } = await request({
            url: `reservation/history`,
            method: "GET",
            params: params
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

export {
    fetchDeleted,
    fetchMonthCalendar,
    fetchDayCalendar,
    fetchReservationsHistory
};