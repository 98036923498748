import styled from "styled-components";

export const ChartContainer = styled.div`
  width: 27rem;
  height: 22rem;
  margin: 3.3rem;
  border: 1px solid #F0F0F3;
  border-radius: 10px 10px 0 0;
  padding: 0 1rem;
  h3{
    color: #CB444A;
    margin-bottom: 1.5rem;
    padding: 2rem;
  }
  ul{
    width: 27rem;
    padding: 0 1rem;
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    position: relative;
    top: -.6rem;
    left: -17px;
    border: 1px solid #F0F0F3;
    border-radius: 0 0 10px 10px;
    li{
      color: #8181A5;
      //color: #CB444A;
    }
  }
`;