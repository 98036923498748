import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Form, MainContent} from "../../../Layout/index";
import Header from "../../../Header";
import {Button, Input} from "../../../Button";
import {url} from "../../../../utils/request";
import * as S from "../../style";
import {useNavigate} from "react-router-dom";
import {showAbsence} from "../../../../services/absences.service";
import {fetchAllCounters} from "../../../../services/counters.service";


const EditEmployeeAbsence = () => {
    const navigate = useNavigate();

    const [dateFrom, setDateFrom] = useState("");
    const [timeFrom, setTimeFrom] = useState("");
    const [timeTo, setTimeTo] = useState("");
    const [positions, setPositions] = useState(null);
    const [selectedPosId, setSelectedPosId] = useState();
    const [oldSelectedPosId, setOldSelectedPosId] = useState();

    useEffect(() => {
        const absenceId = localStorage.getItem("editedAbsenceId");
        const depId = JSON.parse(localStorage.getItem("selectedDepId"));

        showAbsence(absenceId, depId)
            .then(res => {
                setOldSelectedPosId(res.absent.counter_id);
                setSelectedPosId(res.absent.counter_id);
                setDateFrom(res.absent.from.slice(0,10))
                setTimeFrom(res.absent.from.slice(11,16))
                setTimeTo(res.absent.to.slice(11,16))
            })
            .catch(err => console.log(err))

        fetchAllCounters(depId)
            .then(res => {
                setPositions(res.items)
            })
            .catch(err => console.log(err))
    }, [])

    const editAbsence = (e) => {
        e.preventDefault();

        const value = {
            "counter_id": selectedPosId,
            "from": `${dateFrom} ${timeFrom}`,
            "to": `${dateFrom} ${timeTo}`,
            "department_id": JSON.parse(localStorage.getItem("departments"))[0].id
        }
        console.log(value);

        axios.put(`${url}absent/${localStorage.getItem("editedAbsenceId")}`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success(`Pomyślnie edytowano nieobecność`);
                navigate("/panel-employee/nieobecnosci")
            })
            .catch((err) => {
                console.log(err);
                toast.error(`Błąd! Spróbuj ponownie`)
            })
    }

    return (
        <>
            <Header title="Edytuj nieobecność" />
            <S.EmployeeAbsences style={{height: "100%"}}>
                <MainContent>
                    <Form onSubmit={editAbsence}>
                        <h4>Aktualne stanowisko: {(positions && oldSelectedPosId) && positions.find(p => p.id === oldSelectedPosId).name}</h4>
                        <div style={{maxWidth: "20rem"}}>
                            <div className="select-wrapper">
                                <select onChange={(e) => {
                                    setSelectedPosId(e.target.value);
                                }} name="" id="" >
                                    <option disabled selected value="">Zmień stanowisko</option>
                                    {positions && positions.map(d => (
                                        <option key={d.id} value={d.id}>{d.name}</option>
                                    ))}
                                </select>
                            </div>
                            <h4>Data</h4>
                            <Input
                                type="date"
                                style={{width: "16.2rem"}}
                                onChange={e => setDateFrom(e.target.value)}
                                value={dateFrom}
                            />
                            <h4>Godz od</h4>
                            <Input
                                type="time"
                                style={{width: "16.2rem"}}
                                onChange={e => setTimeFrom(e.target.value)}
                                value={timeFrom}
                            />
                            <h4>Godz do</h4>
                            <Input
                                type="time"
                                style={{width: "16.2rem"}}
                                onChange={e => setTimeTo(e.target.value)}
                                value={timeTo}
                            />
                        </div>
                        <Button
                            type="submit"
                            value="Zapisz"
                        />
                    </Form>
                </MainContent>
            </S.EmployeeAbsences>
            <ToastContainer/>
        </>
    )
};

export default EditEmployeeAbsence;