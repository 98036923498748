import React, {useState} from "react";
import axios from "axios";
import {Button, Input} from "../../../Button/index";
import Header from "../../../Header/index";
import {MainContent, Form} from "../../../Layout/index";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";

const AddNewDepartmentComponent = ({onChangeName, nameValue, isPosValue, onChangePos}) => {
    return(
        <div style={{display: "block"}}>
            <Input
                type="text"
                placeholder="Nazwa stanowiska"
                onChange={onChangeName}
                value={nameValue}
                style={{width: "20rem"}}
            />
            <input style={{marginLeft: "11px"}} type="checkbox" onChange={onChangePos} checked={isPosValue}/>
            <label>To stanowisko jest POSem</label>
        </div>
    )
}

const AddDepartment = () => {
    const navigate = useNavigate();

    const [departmentName, setDepartmentName] = useState("");
    const [positions, setPositions] = useState([{name: "", is_pos: 0}])

    const newPosition = {name: "", is_pos: 0};

    const changeName = index => e => {
        let newArr = [...positions];
        newArr[index].name = e.target.value;
        setPositions(newArr);
    }
    const changePos = index => e => {
        let newArr = [...positions];
        newArr[index].is_pos = newArr[index].is_pos === 1 ? 0 : 1;
        setPositions(newArr);
    }
    const addNewDepartment = (e) => {
        e.preventDefault();
        const value = {
            "name": departmentName,
            "counters": positions.map(p => (
                {
                    "name": p.name,
                    "is_pos": p.is_pos
                }
            ))
        };

        axios.post("https://api-ewizyty.wsparcie-jarjarocin.pl/api/V1/panel/department", value , {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success("Pomyślnie dodano wydział");
                navigate('/panel-admin/wydzialy');

            })
            .catch(() => toast.error("Uzupełnij wszystkie pola!"));
    }

    return(
        <>
            <Header title="Dodaj Wydział" />
            <MainContent>
                <Form onSubmit={addNewDepartment}>
                    <Input
                        type="text"
                        placeholder="Nazwa wydziału"
                        onChange={(e) => setDepartmentName(e.target.value)}
                        value={departmentName}
                        style={{width: "20rem"}}
                    />
                    <h4>Określ ilość i nazwy stanowisk</h4>
                    {positions.map((p, index) => (
                        <AddNewDepartmentComponent
                            key={index}
                            nameValue={p.name}
                            isPosValue={p.is_pos}
                            onChangeName={changeName(index)}
                            onChangePos={changePos(index)}
                        />
                    ))}
                    <Button
                        type="button"
                        value="Dodaj kolejne stanowisko"
                        style={{display: "block", margin: ".75rem"}}
                        onClick={() => setPositions([...positions, newPosition])}
                    />
                    <Button
                        type="submit"
                        value="Zapisz"
                    />
                </Form>
            </MainContent>
            <ToastContainer/>
        </>
    )
};

export default AddDepartment;