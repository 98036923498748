import React from 'react';
import './search.css';

const SearchInput = ({style, value, onChange, onClick}) => {
    return(
        <form className="search-form" style={style}>
            <input value={value} onChange={onChange} type="search" placeholder="Wyszukaj" />
            <button onClick={onClick} type="submit">Search</button>
        </form>
    )
};
export default SearchInput;