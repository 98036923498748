import React, {useState, useEffect} from "react";
import Header from "../../Header";
import {MainContent} from "../../Layout";
import List, {ListTitle, ListItem} from "../../List";
import {ArrowDown, EyeIcon, CloseIcon} from "../../../images";
import Pagination from "../../Pagination";
import Modal from 'react-modal';
import "./style.css";
import LoadingComponent from "../../Loading";
import {fetchAdminsNotifications, showAdminsNotification} from "../../../services/notifications.service";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "800px",
        height: "600px",
        backgroundColor: "#fff",
        border: "none",
        position: "relative"
    },
    overlay:{
        backgroundColor: 'rgba(148,148,148,0.63)'
    }
};

const Notifications = () => {

    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    async function openModal(id) {
        await showAdminsNotification(id)
            .then((res) => {
                setSelectedNotification(res.notification);
            })
            .catch((err) => {
                console.log(err);
            })
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
        setSelectedNotification(null);
    }

    const [notifications, setNotifications] = useState(null);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        fetchAdminsNotifications(page)
            .then(res => {
                setLastPage(res.lastPage);
                setNotifications(res.items);
            })
            .catch(err => console.log(err));
    }, [page]);

    return(
        <>
            <Header title="Powiadomienia"/>
            <MainContent>
                <List>
                    <ListTitle>
                        <div style={{width: "5%"}}>
                            <p>Id</p>
                            <img src={ArrowDown} alt="arror-down"/>
                        </div>
                        <div style={{width: "18%"}}>
                            <p>Godzina i data</p>
                            <img src={ArrowDown} alt="arror-down"/>
                        </div>
                        <div style={{width: "32%"}}>
                            <p>Tytuł powiadomienia</p>
                            <img src={ArrowDown} alt="arror-down"/>
                        </div>
                        <div style={{width: "40%"}}>
                            <p>Wysłane do</p>
                            <img src={ArrowDown} alt="arror-down"/>
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Akcja</p>
                        </div>
                    </ListTitle>
                    {notifications && notifications.map(n =>
                        <ListItem key={n.id}>
                            <div style={{width: "5%"}}>
                                <p>{n.id}</p>
                            </div>
                            <div style={{width: "18%"}}>
                                <p>{n.sent}</p>
                            </div>
                            <div style={{width: "32%"}}>
                                <p>{n.title}</p>
                            </div>
                            <div style={{width: "40%"}}>
                                <p>{n.receiver}</p>
                            </div>
                            <div className="list_action_container" style={{width: "15%"}}>
                                <img onClick={() => openModal(n.id)} src={EyeIcon} alt="eye-icon"/>
                            </div>
                        </ListItem>
                    )}
                </List>
                {!notifications && <LoadingComponent/>}
            </MainContent>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                {
                    selectedNotification &&
                    <>
                        <div className="notif-modal-title">
                            <h2>{selectedNotification.sent && selectedNotification.sent.slice(0, 16)}</h2>
                            <h2>{selectedNotification.title && selectedNotification.title}</h2>
                            <p>{selectedNotification.content && selectedNotification.content}</p>
                            <h3>Wysłane do {selectedNotification.receiver && selectedNotification.receiver}</h3>
                            <h3 className="red">Wysłane przez {selectedNotification.sender && selectedNotification.sender}</h3>
                        </div>
                        <img onClick={closeModal} className="close-icon" src={CloseIcon} alt="close-icon"/>
                    </>
                }

            </Modal>
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </>
    )
};

export default Notifications;