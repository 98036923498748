import React, {useEffect, useState} from "react";
import Header from "../../Header";
import {MainContent} from "../../Layout";
import List, {ListTitle, ListItem} from "../../List";
import {ArrowDown} from "../../../images";
import Pagination from "../../Pagination";
import LoadingComponent from "../../Loading";
import SearchInput from "../../SearchInput/SearchInput";
import {fetchDeleted} from "../../../services/reservations.service";

const EmployeeDeleted = () => {

    const [selectedDepId, setSelectedDepId] = useState((JSON.parse(localStorage.getItem("selectedDepId"))));
    const [departments, setDepartments] = useState(JSON.parse(localStorage.getItem("departments")));
    const [deletedList, setDeletedList] = useState(null);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState(false);

    useEffect(() => {
        const params = {
            page: page,
            department_id: selectedDepId,
            search: search
        }
        fetchDeleted(params)
            .then(res => {
                setDeletedList(res.items);
                setLastPage(res.lastPage);
            })
            .catch(err => console.log(err))
    }, [selectedDepId, page, filter]);
    useEffect(() => localStorage.setItem("selectedDepId", selectedDepId.toString()), [selectedDepId]);


    return(
        <>
            <Header title="Usunięte">
                <div className="select-wrapper">
                    <select onChange={(e) => {
                        setSelectedDepId(e.target.value);
                    }} name="" id="" >
                        {departments && departments.map(d => (
                            <option selected={d.id === selectedDepId} key={d.id} value={d.id}>{d.name}</option>
                        ))}
                    </select>
                </div>
            </Header>
            <MainContent>
                <div style={{position: "relative", width: "100%"}}>
                    <SearchInput
                        onClick={(e) => {
                            e.preventDefault();
                            setFilter(!filter);
                        }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            if(!e.target.value.length) setFilter(!filter);
                        }}
                        style={{position: "absolute", right: "3rem", top: "2rem"}}
                    />
                </div>
                <List>
                    <ListTitle>
                        <div style={{width: "20%"}}>
                            <p>Data Godz Rezerwacji</p>
                            <img src={ArrowDown} alt="arrow-down"/>
                        </div>
                        <div style={{width: "20%"}}>
                            <p>Data Godz Usunięcia</p>
                            <img src={ArrowDown} alt="arrow-down"/>
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Imię i nazwisko</p>
                            <img src={ArrowDown} alt="arrow-down"/>
                        </div>
                        <div style={{width: "30%"}}>
                            <p>Stanowisko</p>
                            <img src={ArrowDown} alt="arrow-down"/>

                        </div>
                        <div style={{width: "15%"}}>
                            <p>Numer telefonu</p>
                            <img src={ArrowDown} alt="arrow-down"/>

                        </div>
                    </ListTitle>
                    {deletedList && deletedList.map(d => (
                        <ListItem key={d.id}>
                            <div style={{width: "20%"}}>
                                <p>{d.from.slice(0, 10)} {d.from.slice(11, 16)}</p>
                            </div>
                            <div style={{width: "20%"}}>
                                <p>{d.deleted_at.slice(0, 10)} {d.deleted_at.slice(11, 16)}</p>
                            </div>
                            <div style={{width: "15%"}}>
                                <p>{d.name}</p>
                            </div>
                            <div style={{width: "30%"}}>
                                <p>{d.counter}</p>
                            </div>
                            <div style={{width: "15%"}}>
                                <p>{d.phone}</p>
                            </div>
                        </ListItem>
                    ))}
                </List>
                {!deletedList && <LoadingComponent />}
            </MainContent>
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </>
    )
};

export default EmployeeDeleted;