import React, {useState, useEffect} from "react";
import axios from "axios";
import {url} from "../../../../utils/request";
import Header from "../../../Header";
import * as S from './style';
import List, {ListTitle, ListItem} from "../../../List";
import {Button} from "../../../Button";
import {ArrowDown} from "../../../../images";
import Pagination from "../../../Pagination";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import LoadingComponent from "../../../Loading";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "800px",
        height: "600px",
        backgroundColor: "#fff",
        border: "none",
        position: "relative"
    },
    overlay:{
        backgroundColor: 'rgba(148,148,148,0.63)'
    }
};

const ViewBox = ({children, style}) => {
    return(
        <S.ViewBox style={style}>
            {children}
        </S.ViewBox>
    )
}

const convertStatuses = status => {
    switch (status) {
        case "new":
            return "Nowa"
        case "in_progress":
            return "W trakcie"
        case "done":
            return "Załatwiona"
        case "missing":
            return "Brak petenta"
        default:
            return "Nowa"
    }
}

const ReservationView = () => {

    const [reservation, setReservation] = useState(null);
    const [logs, setLogs] = useState(null);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const [message, setMessage] = useState('');
    const [sms, setSms] = useState(false);
    const [email, setEmail] = useState(false);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [secondIsOpen, setSecondIsOpen] = useState(false);
    const [schemas, setSchemas] = useState([]);

    const [showSchemas, setShowSchemas] = useState(false);
    const [selectedSchemaId, setSelectedSchemaId] = useState(null);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function openSecondModal() {
        setSecondIsOpen(true);
    }

    function closeSecondModal() {
        setSecondIsOpen(false);
    }

    const handleProperName = (logs1) => {
        let trueLogs = [];
        for (let i = 0; i < logs1.length; i++){

            let el = logs1[i];
            const newText = el.message.toString().replace("patent", "petent");
            el.message = newText;
            trueLogs.push(el);
        }
        return trueLogs;
    };



    useEffect(() => {
        axios.get(`${url}reservation/${localStorage.getItem("reservationId")}?department_id=${localStorage.getItem("selectedDepId")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setReservation(res.data.reservation)
            })
            .catch(err => console.log(err))

        axios.get(`${url}reservation/logs/${localStorage.getItem("reservationId")}?department_id=${localStorage.getItem("selectedDepId")}&page=${page}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setLogs(res.data.items);
                setLastPage(res.data.lastPage);
            })
            .catch(err => console.log(err))
        axios.get(`${url}notificationSchema/all?department_id=${localStorage.getItem("selectedDepId")}`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setSchemas(res.data.items);
                if(res.data.items.length){
                    setSelectedSchemaId(res.data.items[0].id);
                }
            })
            .catch(err => console.log(err));
    }, [page, refresh]);

    const sendMessage = () => {
        const value = {
            "email": email ? 1 : 0,
            "sms": sms ? 1 : 0,
            "title": "Urząd Miasta Jarocin",
            "content": message
        }
        axios.post(`${url}reservation/notification/custom/${localStorage.getItem("reservationId")}?department_id=${localStorage.getItem("selectedDepId")}`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(() => {
                toast.success(`Pomyślnie wysłano wiadomość`);
                setMessage('')
                setEmail(false)
                setSms(false)
                setRefresh(!refresh)
            })
            .catch(() => {
                toast.error(`Błąd! Uzupełnij dane`);
            })
    };

    const changeStatus = (status) => {
                axios.put(`${url}reservation/status/${localStorage.getItem("reservationId")}?department_id=${localStorage.getItem("selectedDepId")}&status=${status}`, {}, {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
                    }
                })
                    .then(() => {
                        toast.success(`Pomyślnie wykonano operację`);
                        setRefresh(!refresh);
                    })
                    .catch(() => {
                        toast.error(`Błąd! Spróbuj ponownie`);
                    })

                closeModal();
    }

    const addFutureNotif = () => {

        const value = {
            "sms": 1,
            "email": 1,
            "notification_schema_id": selectedSchemaId
        };

        axios.post(`${url}reservation/notification/schema/${localStorage.getItem("reservationId")}?department_id=${localStorage.getItem("selectedDepId")}`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => console.log(res))
            .catch(err => console.log(err));
    };

    const getProperMinutes = (minutes) => {
        if(minutes.toString().length === 1) {
            return `0${minutes}`;
        }else {
            return minutes;
        }
    };

    const handleTime = (time) => {
        const date = new Date(time);
        return `${date.getHours()}:${getProperMinutes(date.getMinutes())}`
    };

    return (
        <>
            <Header title={`${reservation && reservation.from.slice(0,10)}  ${reservation && reservation.from.slice(11,16)}`}/>
            <div style={{display: "flex", justifyContent: "space-between", overflow: "auto"}}>
                <ViewBox style={{width: "70%", height: "260px"}}>
                    {reservation &&
                        <div style={{margin: "1rem"}}>
                            <p>{reservation.from.slice(0,10)} {reservation.from.slice(11,16)}</p>
                            <h3>{reservation.name}</h3>
                            <h3>{reservation.phone}</h3>
                            <h3>{reservation.email}</h3>
                            <h3 style={{marginTop: "2rem"}}>{reservation.category.name}</h3>
                        </div>
                    }
                </ViewBox>
                {reservation &&
                    <ViewBox style={{width: "29%", height: "260px", display: "flex", alignItems: "center", flexDirection: "column", gap: "1rem"}}>
                                <p>Status: <span>{convertStatuses(reservation.status)}</span></p>
                                <Button
                                    type="button"
                                    value="Rozpocznij sprawę"
                                    style={{backgroundColor: "#46B489", textTransform: "uppercase"}}
                                    onClick={() => {
                                        if(convertStatuses(reservation.status) === "W trakcie"){
                                            toast.error("Sprawa już jest w trakcie")
                                        } else {
                                            changeStatus("in_progress");
                                        }
                                    }}
                                />
                                <Button
                                    type="button"
                                    value="Zakończ sprawę"
                                    style={{backgroundColor: "#CB444A", textTransform: "uppercase"}}
                                    onClick={() => {
                                        if(convertStatuses(reservation.status) === "Załatwiona"){
                                            toast.error("Sprawa już jest załatwiona")
                                        } else {
                                            openModal();
                                        }
                                    }}
                                />
                                <Button
                                    type="button"
                                    value="Petent nie przyszedł"
                                    style={{textTransform: "uppercase"}}
                                    onClick={() => {
                                        if(convertStatuses(reservation.status) === "Brak petenta"){
                                            toast.error("Operacja jest już wykonana")
                                        } else {
                                            changeStatus("missing");
                                        }
                                    }}
                                />
                    </ViewBox>
                }
            </div>
            <ViewBox style={{width: "100%", height: "200px", margin: "1rem 0"}}>
                <h4>Napisz do tego użytkownika</h4>
                <div style={{display: "flex", alignItems: "center", gap: "1rem", marginLeft: "1rem"}}>
                    <div className="checkbox-box">
                        <input checked={sms} onChange={() => setSms(!sms)} type="checkbox"/>
                        <span>SMS</span>
                    </div>
                    <div className="checkbox-box">
                        <input checked={email} onChange={() => setEmail(!email)} type="checkbox"/>
                        <span>Email</span>
                    </div>
                    <input className='textarea' placeholder="Treść wiadomości" value={message} onChange={e => setMessage(e.target.value)}/>
                    <Button type="button" onClick={sendMessage} value="Wyślij"/>
                </div>
            </ViewBox>
            <ViewBox style={{width: "100%", height: "auto"}}>
                <h4>Logi</h4>
                <List style={{top: "0"}}>
                    <ListTitle>
                        <div style={{width: "20%"}}>
                            <p>Użytkownik</p>
                            <img src={ArrowDown} alt="arrow-down"/>
                        </div>
                        <div style={{width: "20%"}}>
                            <p>Data i godz</p>
                            <img src={ArrowDown} alt="arrow-down"/>
                        </div>
                        <div style={{width: "60%"}}>
                            <p>Czynność</p>
                            <img src={ArrowDown} alt="arrow-down"/>
                        </div>
                    </ListTitle>
                    {logs && handleProperName(logs).map(l => (
                        <ListItem>
                            <div style={{width: "20%"}}>
                                <p>{l.user_name}</p>
                            </div>
                            <div style={{width: "20%"}}>


                                <p>{l.created_at.slice(0,10)} {handleTime(l.created_at)}</p>



                            </div>
                            <div style={{width: "60%"}}>
                                <p>{l.message}</p>
                            </div>
                        </ListItem>
                    ))}
                </List>
                {!logs && <LoadingComponent style={{position: "relative", top: "-120px"}}/>}
                <Pagination page={page} setPage={setPage} lastPage={lastPage} />
            </ViewBox>
            <ToastContainer/>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <h3 style={{marginTop: "10rem", fontSize: "1.5rem"}}>Czy na pewno chcesz zakończyć tę sprawę?</h3>
                    <div style={{marginTop: "5rem", display: "flex", width: "15rem", justifyContent: "space-between"}}>
                        <Button type="button" onClick={() => {
                            openSecondModal();
                        }} value="Tak" style={{backgroundColor: "#CB444A"}}/>
                        <Button type="button" onClick={() => {
                            closeModal();
                        }} value="Nie"/>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={secondIsOpen}
                onRequestClose={closeSecondModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <h3 style={{marginTop: "10rem", fontSize: "1.5rem", textAlign: "center"}}>Czy ta sprawa <br/>
                        wymaga wysłania powiadomienia w przyszłości?</h3>
                    <div >
                        {!showSchemas &&
                            <>
                            {schemas.length ?
                                <div style={{marginTop: "5rem", display: "flex", width: "15rem", justifyContent: "space-between"}}>
                                    <Button style={{backgroundColor: "#CB444A"}} type="button" value="Tak" onClick={() => {
                                        setShowSchemas(true);
                                    }}/>
                                    <Button type="button" onClick={() => {
                                        changeStatus("done");
                                        closeSecondModal();
                                    }} value="Nie"/>
                                </div>
                            :
                                <div style={{display: "flex", width: "15rem", justifyContent: "center", flexDirection:"column"}}>
                                    <div style={{marginTop: "5rem", display: "flex", width: "15rem", justifyContent: "space-between"}}>
                                        <Button
                                            style={{backgroundColor: "#CB444A", opacity: ".3", cursor: "auto"}}
                                            type="button"
                                            value="Tak"
                                        />
                                        <Button
                                            style={{opacity: ".3", cursor: "auto"}}
                                            type="button"
                                            value="Nie"
                                        />
                                    </div>
                                    <p style={{color: "#CB444A", fontSize: "1.2rem", textAlign: "center"}}>Brak przypisanego szablonu powiadomień dla danego wydziału</p>
                                    <Button onClick={() => {
                                        closeModal();
                                        closeSecondModal();
                                        changeStatus("done");
                                    }} type="button" value="Zamknij"/>
                                </div>
                            }
                            </>
                        }
                        {showSchemas &&
                            <div style={{display: "flex", width: "20rem", justifyContent: "center", flexDirection:"column"}}>
                                <p style={{fontSize: "1.2rem", textAlign: "center"}}>Wybierz szablon powiadomienia:</p>
                                <S.Select>
                                    <select onChange={(e) => setSelectedSchemaId(e.target.value)} name="" id="">
                                        {schemas.map(s => <option key={s.id} value={s.id}>{s.title}</option>)}
                                    </select>
                                </S.Select>
                                <Button
                                    onClick={() => {
                                        if(selectedSchemaId){
                                            closeModal();
                                            closeSecondModal();
                                            changeStatus("done");
                                            addFutureNotif();
                                        } else {
                                            toast.error(`Błąd! Wybierz szablon`);
                                        }
                                    }}
                                    type="button" style={{width: "10rem", left: "50%", position: "relative", transform: "translate(-50%, 0)"}} value="Zatwierdz"/>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
};

export default ReservationView;