import React, {useContext} from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import plLocale from "@fullcalendar/core/locales/pl";
import {useOutletContext, useNavigate} from "react-router-dom";
import LoadingComponent from "../../Loading";
import {LoadingContext} from "../../../App";

// export const MonthGrid = ({events, active, setActive, setEventDate, setStartMonthDate, setEndMonthDate}) => {


// document.querySelector('.fc-next-button').addEventListener('click', function() {
//     console.log('next');
// });

export const MonthGrid = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useContext(LoadingContext);
    // const [startMonthDate, setStartMonthDate, endMonthDate, setEndMonthDate] = useContext(MonthContext);

    // const [eventDate, truePositions, date, setEventDate, trueMonth] = useOutletContext();
    const [eventDate, truePositions, date, setEventDate, trueMonth, startMonthDate, setStartMonthDate, endMonthDate, setEndMonthDate, refresh, setRefresh] = useOutletContext();


    return(
        <>
            {loading ?
        <FullCalendar
            datesSet={(date) => {
                setStartMonthDate(date.startStr.slice(0, 10));
                setEndMonthDate(date.endStr.slice(0, 10));
            }}
            plugins={[dayGridPlugin]}
            locale={plLocale}
            timeZone={'Europe/Warsaw'}
            initialView="dayGridMonth"
            schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'}
            eventSources={[
                {
                    events: trueMonth.reservations,
                    color: '#FAECED',
                    textColor: '#CB444A'
                },
                {
                    events: trueMonth.absents,
                    color: '#F0F0F3',
                    textColor: '#212226'
                },
            ]}
            eventClick={(e) => {
                setLoading(false);
                if(localStorage.getItem("userRole").toString() === "2") {
                    navigate('/panel-employee/calendar/day')
                } else {
                    navigate('/panel-admin/kalendarze/calendar/day')
                }
                setEventDate(e.event.start);
            }}
        />
                :
                <LoadingComponent style={{marginTop: "100px"}}/>
            }
        </>
    )
};

export default MonthGrid;