import React, {useState, useEffect} from "react";
import Header from "../../../Header/index";
import {MainContent, Form} from "../../../Layout/index";
import {Input, Button} from "../../../Button/index"
import * as S from "../style";
import axios from "axios";
import {url} from "../../../../utils/request";
import {TrashIcon} from "../../../../images/index";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";

const Case = ({name, time, room, onClick}) => {

    return(
        <div style={{marginLeft: "2.3rem", display: "flex", alignItems: "center"}}>
            <div className="selected-case" style={{width: "20rem"}}>{name}</div>
            <div className="selected-case" style={{width: "10rem", marginLeft: "1rem"}}>{time}</div>
            <div className="selected-case" style={{width: "10rem", marginLeft: "1rem"}}>{room}</div>

            <img
                src={TrashIcon}
                alt="trash-icon"
                style={{marginBottom: "1.5rem"}}
                onClick={onClick}
            />
        </div>
    )
}

const AddCase = () => {
    const navigate = useNavigate();

    const [departments, setDepartments] = useState(null);
    const [chosenDepId, setChosenDepId] = useState();
    const [name, setName] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [time, setTime] = useState("");
    const [room, setRoom] = useState("");

    const [subCategories, setSubCategories] = useState([]);

    useEffect(() => {
        axios.get(`${url}department/all`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                console.log(res);
                setDepartments(res.data.items);
            })
            .catch(err => console.log(err))
    }, []);

    const addCase = () => {
        if(subCategory.length > 1 && time > 1) {
            setSubCategories([...subCategories, {name: subCategory, time: time, room_number: room}]);
            setSubCategory('');
            setTime('');
            setRoom('');
        } else toast.error(`Błąd! Uzupełnij dane`);
    }

    const saveCase = (e) => {
        e.preventDefault();
        const value = {
            "department_id": chosenDepId,
            "name": name,
            "children": subCategories
        }
        console.log(value);

        axios.post(`${url}category`, value, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                toast.success(`Pomyślnie dodano sprawę`);
                navigate('/panel-admin/sprawy');

            })
            .catch(err => {
                toast.error(`Błąd! Spróbuj ponownie`);
            });
    };

    return (
        <>
            <Header title="Dodaj Sprawę"/>
            <MainContent>
                <Form onSubmit={saveCase}>
                    <S.CasesContainer>
                        <div className="select-wrapper">
                            <select  onChange={(e) => setChosenDepId(e.target.value)} name="" id="" >
                                <option disabled selected value="">Wydział</option>
                                {departments && departments.map(d => (
                                    <option key={d.id} value={d.id}>{d.name}</option>
                                ))}
                            </select>
                        </div>
                        <Input
                            type="text"
                            placeholder="Kategoria"
                            style={{width: "20rem"}}
                            onChange={e => setName(e.target.value)}
                            value={name}
                        />
                        <div style={{
                            marginLeft: "2.3rem",
                            width: "80rem"}}>
                            <Input
                                type="text"
                                placeholder="Podkategoria"
                                style={{width: "20rem"}}
                                onChange={e => setSubCategory(e.target.value)}
                                value={subCategory}
                            />
                            <Input
                                type="number"
                                placeholder="Czas realizacji w min"
                                style={{width: "10rem", marginLeft: "1rem"}}
                                onChange={e => setTime(e.target.value)}
                                value={time}
                                step="5"
                                min={0}
                            />
                            <Input
                                type="text"
                                placeholder="Pokój numer X oraz Y"
                                style={{width: "10rem", marginLeft: "1rem"}}
                                onChange={e => setRoom(e.target.value)}
                                value={room}
                            />
                            <input onClick={addCase} type="button" className="add_button" value="+"/>
                        </div>
                        {subCategories.map(c =>
                            <Case name={c.name} time={c.time} room={c.room_number} onClick={() => {
                                const name = c.name;
                                setSubCategories(subCategories.filter(item => item.name !== name));
                            }}/>
                        )}
                    </S.CasesContainer>
                    <Button
                        type="submit"
                        value="Zapisz"
                    />
                </Form>
            </MainContent>
            <ToastContainer/>
        </>
    )
}

export default AddCase;