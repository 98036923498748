import request from "../utils/request";

const fetchAllCounters = async (id) => {
    try {
        const { data, status } = await request({
            url: `counter/all?department_id=${id}`,
            method: "GET",
        });
        if (status === 200) {
            return data;
        }
        return data;
    } catch (err) {
        return err
    }
};

export {
    fetchAllCounters
};