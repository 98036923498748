import React, {useEffect, useState} from "react";
import Header from "../../Header";
import {MainContent} from "../../Layout";
import List, {ListItem, ListTitle} from "../../List";
import {ArrowDown, EditIcon, TrashIcon} from "../../../images";
import SearchInput from "../../SearchInput/SearchInput";
import {fetchReservationsHistory} from "../../../services/reservations.service";
import LoadingComponent from "../../Loading";
import Pagination from "../../Pagination";

const History = () => {

    const [selectedDepId, setSelectedDepId] = useState((JSON.parse(localStorage.getItem("selectedDepId"))));
    const [departments, setDepartments] = useState(JSON.parse(localStorage.getItem("departments")));
    const [items, setItems] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const [search, setSearch] = useState('');

    useEffect(() => {
        let tempParams = {
            department_id: selectedDepId,
            page: page,
            search: search ?? ''
        };
        fetchReservationsHistory(tempParams)
            .then(res => {
                console.log(res);
                setItems(res.items);
                setLastPage(res.lastPage);
            })
            .catch(err => console.log(err));
    }, [selectedDepId, page, refresh]);
    // const history = [
    //     {
    //         id: 1,
    //         name: "Jan Kowalski",
    //         phone: 765765765,
    //         date: "jak chcesz",
    //         category: "Odebranie dowodu",
    //         status: "new"/"in_progress"/"done"/"missing"/"canceled"
    //     }
    // ]



    return (
        <>
            <Header title="Historia rezerwacji" >
                <div className="select-wrapper">
                    <select onChange={(e) => {
                        setSelectedDepId(e.target.value);
                    }} name="" id="" >
                        {departments && departments.map(d => (
                            <option selected={d.id === selectedDepId} key={d.id} value={d.id}>{d.name}</option>
                        ))}
                    </select>
                </div>
            </Header>
            <MainContent>
                <div style={{position: "relative", width: "100%"}}>
                    <SearchInput
                        onClick={(e) => {
                            e.preventDefault();
                            setRefresh(!refresh)
                            // setFilter(!filter);
                        }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            // if(!e.target.value.length) setFilter(!filter);
                        }}
                        style={{position: "absolute", right: "3rem", top: "2rem"}}
                    />
                </div>
                <List>
                    <ListTitle>
                        <div style={{width: "15%"}}>
                            <p>Data i godzina</p>
                            <img
                                src={ArrowDown}
                                alt="arror-down"
                            />
                        </div>
                        <div style={{width: "20%"}}>
                            <p>Imię i nazwisko</p>
                            <img
                                src={ArrowDown}
                                alt="arror-down"
                            />
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Numer telefonu</p>
                            <img
                                src={ArrowDown}
                                alt="arror-down"
                            />
                        </div>
                        <div style={{width: "25%"}}>
                            <p>Kategoria</p>
                            <img
                                src={ArrowDown}
                                alt="arror-down"
                            />
                        </div>
                        <div style={{width: "15%"}}>
                            <p>Status</p>
                            <img
                                src={ArrowDown}
                                alt="arror-down"
                            />
                        </div>
                    </ListTitle>
                    {items && items.map( item =>
                        <ListItem key={item.id}>
                            <div style={{width: "15%"}}>
                                <p>{item.from}</p>
                            </div>
                            <div style={{width: "20%"}}>
                                <p>{item.name}</p>
                            </div>
                            <div style={{width: "15%"}}>
                                <p>{item.phone}</p>
                            </div>
                            <div style={{width: "25%"}}>
                                <p>{item.category_name}</p>
                            </div>
                            <div style={{width: "15%"}}>
                                <p>{item.current_status}</p>
                            </div>
                        </ListItem>
                    )}
                </List>
                {!items && <LoadingComponent/>}
            </MainContent>
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </>
    )
};

export default History;